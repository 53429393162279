import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconSearch from '../adminIcons/IconSearch.svg'
import { getAlbumsLength, getAlbumsForAdmin, getTracksLength } from '../../actions/AdminFilterActions';


const SearchAlbums = (props) => {

    const searchAlbumRef = useRef()

    const dispatch = useDispatch()
  
    const {adminAlbumsLoading, adminAlbums} = useSelector(state => state.adminAlbums)

    const [albumActive, setAlbumActive] = useState(false)

    const [chosenAlbums, setChosenAlbums] = useState([])

    const [page, setPage] = useState(1)

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (props.albumValue){
                dispatch(getAlbumsForAdmin(props.albumValue, 1, 0))
            } else {
                dispatch(getAlbumsForAdmin(" ", 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[props.albumValue])

    useEffect(()=>{
        if (props.albumValue){
            dispatch(getAlbumsForAdmin(props.albumValue, page, 1))
        } else {
            dispatch(getAlbumsForAdmin(" ", page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchAlbumRef == null || searchAlbumRef.current == null || !searchAlbumRef.current.contains(event.target)){
            // setAlbumActive(false)
            }
        })
    }) 


    const executeSearch = (value) => {
        if(value && value.length){
            setPage(1)
            dispatch(getAlbumsLength(value))
            dispatch(getAlbumsForAdmin(value, 1, 0))
        }
        // setAlbumActive(false)
        props.setComponent(2)
    }
  
    const chooseHandler = (album) => {
        dispatch(getTracksLength(album.id))
        props.setAlbum(album)
        props.setComponent(3)
    }   

    return(
        <> 
            <div className='w-100 d-flex flex-column'>
                <div className='d-flex pb-4' style={{gap:"1rem"}}>
                    <div className = "fw-bold fs-7 text-success">ADD TRACK</div>
                    <div className = "fw-bold fs-7 text-secondary">STEP 1</div>
                    <div className = "fs-7 text-secondary">Find album</div>
                </div>
                <div className='d-flex w-100 justify-content-between align-items-end'>
                    <div  className='w-100'
                    // style={{width:"1200px"}}
                    >
                        <div className = 'd-flex w-100 bg-secondary justify-content-between rounded py-2 flex-wrap'>
                            <input
                                type="text"
                                className="fs-5 w-75 border-0 bg-secondary text-white album-search-input ps-3"
                                placeholder={"Album title, Catalogue number"}
                                onChange={(e)=>props.setAlbumValue(e.target.value)}
                                onClick={()=>{setAlbumActive(true)}}
                                value = {props.albumValue}/>
                            <div className='col-1 d-flex justify-content-end pe-2 bg-secondary'
                                // onClick={()=> executeSearch(props.searchValue)}
                            >
                                <img 
                                    src={IconSearch} 
                                    alt="search icon" 
                                    role= "button" 
                                    className='p-2'
                                    onClick={()=>executeSearch(props.albumValue)} />
                            </div>
                        </div>
                        {
                            albumActive ?
                            <div className = "w-100 position-relative d-flex justify-content-end" 
                                style={{userSelect:"none"}} ref = {searchAlbumRef}>
                                <div className = "w-100 position-absolute mt-3 text-dark p-2 bg-white shadow rounded" 
                                    style = {{zIndex:1000}}>
                                    <div className='addToplaylist-list-wrapper'
                                        onScroll = {handleScroll}
                                        style={{maxHeight:"20rem"}}
                                    >
                                        {
                                            adminAlbumsLoading ? <>Loading...</> :
                                            adminAlbums && adminAlbums.length ? adminAlbums.map((item, index) => (
                                                <div className = "d-flex align-items-center px-1 py-2 text-dark addSearchDropdown-item"
                                                    role = "button"
                                                    style={{transition:"all 0.2s", wordBreak:"normal", lineHeight:"1.125rem"}}
                                                    key = {index}
                                                    onClick = {()=>chooseHandler(item)}
                                                    >
                                                    <div className="px-2 text-info" style={{width:"8%"}}>
                                                        <img 
                                                            src = {`https://img.cugate.com/?o=album&i=${item.id}&s=300`}
                                                            style={{width:"4rem", height:"4rem"}}/>
                                                    </div>
                                                    <div className="px-2 text-secondary" style={{width:"60%"}}>{item.title}</div>
                                                    <div className="px-2 text-secondary" style={{width:"15%"}}>{item.catalogue_num}</div>
                                                </div>
                                            )) : <div className='text-dark'>No result</div>
                                        }
                                    </div>
                                </div>  
                            </div> : "" 
                        }
                    </div>
                </div>
            </div>
        </>
    )

};

export default SearchAlbums;