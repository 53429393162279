import React, {useEffect, useState} from 'react';
import SliderOfGroup from './SliderOfGroup';
import { getAllPlaylists } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'

function GroupSlides(props) {

    const [loungeRef, setLoungeRef] = useState()
    const [concertsRef, setConcertsRef] = useState()
    const [heavenlyRef, setHeavenlyRef] = useState()
    const [healthRef, setHealthRef] = useState()
    const [mysticRef, setMysticRef] = useState()
    const [christmasRef, setChristmasRef] = useState()
    const [timelessRef, setTimelessRef] = useState()
    

    const dispatch = useDispatch()

    const playlistList = useSelector(state => state.allPlaylists)
  
    const {error, loading, allPlaylists} = playlistList
  
  
    useEffect(()=>{
  
      dispatch(getAllPlaylists(props.group_id))
  
    },[dispatch])

    return (
        <section id="groupCarouselsSection">
            <div className="container-fluid px-2 px-lg-5">
                { allPlaylists && allPlaylists.length ? 
                    <div className="row" id="carouselListWrapper">
                        <SliderOfGroup
                            slideRef = {loungeRef}
                            setSlideRef = {setLoungeRef}
                            title = {'Lounges'}
                            group = {allPlaylists.find(group => group.id == 4)}
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {concertsRef}
                            setSlideRef = {setConcertsRef}
                            title = {'Concerts & Performances'}
                            group = {allPlaylists.find(group => group.id == 5)}
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {heavenlyRef}
                            setSlideRef = {setHeavenlyRef}
                            title = {'Heavenly Voices'}
                            group = {allPlaylists.find(group => group.id == 6)}
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {healthRef}
                            setSlideRef = {setHealthRef}
                            title = {'Health & Healing'}
                            group = {allPlaylists.find(group => group.id == 7)}
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {mysticRef}
                            setSlideRef = {setMysticRef}
                            title = {'Mystic of the Horizons'}
                            group = {allPlaylists.find(group => group.id == 8)} 
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {christmasRef}
                            setSlideRef = {setChristmasRef}
                            title = {'Christmas Impressions'}
                            group = {allPlaylists.find(group => group.id == 9)}
                            user = {props.user}
                        />
                        <SliderOfGroup
                            slideRef = {timelessRef}
                            setSlideRef = {setTimelessRef}
                            title = {'Timeless Classics'}
                            group = {allPlaylists.find(group => group.id == 10)} 
                            user = {props.user}
                        />
                    </div> : ''
                }
            </div>
        </section>
    );
}

export default GroupSlides;