import axios from 'axios'

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,

    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,

    AUTHENTICATION_REQUEST,
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_FAIL,

    CHECK_EMAIL_REQUEST,
    CHECK_EMAIL_FAIL,
    CHECK_EMAIL_SUCCESS,

    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,

    ACTIVATION_REQUEST,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,

    PASSWORD_RESET_CONFIRM_REQUEST,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,

    LOGOUT
} from '../constants/AuthConstants'


export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Accept': 'application/json'
              }
        }

        const body = JSON.stringify({ token: localStorage.getItem('access')})

        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/auth/jwt/verify/`, body, config)

            if (res.data.code!= 'token_not_valid'){
                dispatch({
                    type: AUTHENTICATION_SUCCESS,
                })
            } else {
                dispatch({
                    type: AUTHENTICATION_FAIL,
                })
            }

        } catch (error) {
            dispatch({
                type: AUTHENTICATION_FAIL,
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
            })
        }

    } else {
        dispatch({
            type: AUTHENTICATION_FAIL
        })
    }
}


export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
            type: LOAD_USER_REQUEST
            })
    
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/cuviewTV/auth/users/me/`, config
            )

            const session_id = Date.now().toString() + "_" + data.id

            localStorage.setItem("session", session_id)
    
            dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data
            })
    
        } catch(error) {
            dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : ""
            })
        }
    } else {
        dispatch({
            type: LOAD_USER_FAIL,
        })
    }
  }


export const login = (email, password) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({email, password});

    try {
        dispatch({
        type: LOGIN_REQUEST
        })

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/auth/jwt/create`,  body, config
        )

        dispatch({
        type: LOGIN_SUCCESS,
        payload: data
        })

        dispatch(load_user())

    } catch(error) {
        dispatch({
        type: LOGIN_FAIL,
        payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail
        })
    }
}

export const signup = (first_name, last_name, email, password, re_password, mobile_number, gives_permission) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({first_name, last_name, email, password, re_password, mobile_number, gives_permission});
    
    try {
        dispatch({
        type: SIGNUP_REQUEST
        })

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/auth/users/`,  body, config
        )

        dispatch({
        type: SIGNUP_SUCCESS,
        payload: data,
        confirmMessage: 'Check your email to activate account'
        })

    } catch(error) {
        dispatch({
        type: SIGNUP_FAIL,
        payload: error.response && error.response.data.email
        ? error.response.data.email : error.response.data.password 
        ? error.response.data.password :""
        })
    }
}

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({uid, token});

    try {
        dispatch({
        type: ACTIVATION_REQUEST
        })

        await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/auth/users/activation/`,  body, config
        )

        dispatch({
        type: ACTIVATION_SUCCESS,
        })

    } catch(error) {
        dispatch({
        type: ACTIVATION_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const reset_password = (email) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'application/json',
          }
    }

    const body = JSON.stringify({email})

    try { 

        dispatch({
            type: PASSWORD_RESET_REQUEST
            })

            await axios.post(
                `${process.env.REACT_APP_API_URL}/cuviewTV/auth/users/reset_password/`,  body, config
            )

        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            message: "Please, check your email"
            })

    } catch(error) {
        dispatch({
            type: PASSWORD_RESET_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail
            })

    }
}

export const check_email = (email, index) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'application/json',
          }
    }
    try { 

        dispatch({
            type: CHECK_EMAIL_REQUEST
            })

            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/cuviewTV/check_email/`, 
                { 'email': email, 'index': index}, 
                config
            )

        dispatch({
            type: CHECK_EMAIL_SUCCESS,
            payload: res.data
            })

    } catch(error) {
        dispatch({
            type: CHECK_EMAIL_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })

    }
}

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch =>{
    const config = {
        headers:{
            'Content-type':'application/json',
          }
    }

    const body = JSON.stringify({uid, token, new_password, re_new_password});

    try { 

        dispatch({
            type: PASSWORD_RESET_CONFIRM_REQUEST
            })

            await axios.post(
                `${process.env.REACT_APP_API_URL}/cuviewTV/auth/users/reset_password_confirm/`,  body, config
            )

        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS,
            message: "Password has changed successfully"
            })

    } catch(error) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail
            })

    }
}

export const logout = () => dispatch =>{
    dispatch({
        type: LOGOUT
    });
}