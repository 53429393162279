import React, {useEffect, useState, useRef} from 'react';
import { getVideoGenres } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const VideoGenres = (props) => {

    const searchVideoGenreRef = useRef()

    const dispatch = useDispatch()

    const {error, loading, videogenres} = useSelector(state => state.videogenres)

    const [videoGenreActive, setVideoGenreActive] = useState(false)

    const [videoGenreValue, setVideoGenreValue] = useState("")

    const [page, setPage] = useState(1)


    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (videoGenreValue){
                dispatch(getVideoGenres(videoGenreValue, props.filterList, 1, 0))
            } else {
                dispatch(getVideoGenres(" ", props.filterList, 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[videoGenreValue, props.filterList])

    useEffect(()=>{
        if (videoGenreValue){
            dispatch(getVideoGenres(videoGenreValue, props.filterList, page, 1))
        } else {
            dispatch(getVideoGenres(" ", props.filterList, page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchVideoGenreRef == null || searchVideoGenreRef.current == null || !searchVideoGenreRef.current.contains(event.target)){
            setVideoGenreActive(false)
            }
        })
    }) 

    const chooseHandler = (videoGenre) => {
        if (props.filterList.filter(vg => vg[0] === videoGenre.id && vg[2] === 6 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [videoGenre.id, videoGenre.title, 6]]})
        }
        setVideoGenreActive(false)
        setVideoGenreValue("")
    }

    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {videogenres}
                    setValue = {setVideoGenreValue}
                    setActive = {setVideoGenreActive}
                    value = {videoGenreValue}
                    isActive = {videoGenreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchVideoGenreRef}
                    handleScroll = {handleScroll}
                    title = "Video Genres"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {videogenres}
                    setValue = {setVideoGenreValue}
                    setActive = {setVideoGenreActive}
                    value = {videoGenreValue}
                    isActive = {videoGenreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchVideoGenreRef}
                    handleScroll = {handleScroll}
                    title = "Video Genres"
                    loading = {loading}
            />
            }
        </>
    );
};

export default VideoGenres;