import React from 'react'

const Notification = ({message}) => {
    return (
        <div className = "bg-secondary w-100 text-center pt-3 pb-2 border-0 rounded-5 shadow text-nowrap">
            <h6 className = "text-info h-2 fw-light">{message}</h6>
        </div>
    )
}

export default Notification;