import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addGroup } from '../../actions/GroupsActions';
import ExitIcon from '../adminIcons/CrossIconInfo.svg'

const AddTheme = (props) => {

    const [title, setTitle] = useState('')

    const exitHandler = () => {
        setTitle('')
        props.setIsActive(false)
    }

    const dispatch = useDispatch()

    const saveHandler = () => {
        if (!title.length){
            alert('Please, Provide Title')
        } else {
            dispatch(addGroup(title))
            props.setIsActive(false)
        }
    }

    return (
        <div
            style={{
                backgroundColor: "rgba(0,0,0,.6)",
                width:props.width, 
                height:props.height, 
                maxHeight:"fit-content", 
                // overflowY:"scroll",
                paddingTop:"109px"
            }}>
            <div className = 'w-100 d-flex justify-content-center'>
                <div className='col-8 bg-primary rounded p-5 d-flex flex-column admin-search-wrapper'>
                    <div className = 'd-flex justify-content-end mb-1'>
                        <img 
                            src = {ExitIcon}
                            role = 'button'
                            onClick={exitHandler}/>
                    </div>
                        <div className = 'px-2 text-secondary fs-8 pb-3 fw-bold'>ADD TITLE</div>
                        <div className = "col-12 px-2 position-relative d-flex justify-content-end align-items-center" 
                            style={{userSelect:"none", height:"2.5rem"}} 
                            >
                            <div className="w-100 h-100 w-lg-75 col-lg-10 bg-success d-flex py-1 rounded border border-secondary"
                            >
                                <input
                                    type="text"
                                    className="col-11 fs-5 border-0 bg-success text-white search-input ps-3"
                                    placeholder="Write or Select"
                                    onChange={(e)=>{setTitle(e.target.value)}}
                                    value={title}
                                />
                            </div>
                        </div>
                        <div className = 'w-100 pt-5 d-flex justify-content-center'>
                            <div className = "text-white px-5 py-2 bg-secondary rounded-pill"
                                role = 'button'
                                onClick={()=>saveHandler()}
                            >Save</div>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default AddTheme;