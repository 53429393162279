export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST'
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS'
export const GET_VIDEO_FAIL = 'GET_VIDEO_FAIL'

export const VIDEO_CLICKS_REQUEST = 'VIDEO_CLICKS_REQUEST'
export const VIDEO_CLICKS_SUCCESS = 'VIDEO_CLICKS_SUCCESS'
export const VIDEO_CLICKS_FAIL = 'VIDEO_CLICKS_FAIL'

export const VIDEOS_FOR_BANNER_REQUEST = 'VIDEOS_FOR_BANNER_REQUEST'
export const VIDEOS_FOR_BANNER_SUCCESS = 'VIDEOS_FOR_BANNER_SUCCESS'
export const VIDEOS_FOR_BANNER_FAIL = 'VIDEOS_FOR_BANNER_FAIL'

export const GET_VIDEOS_FOR_ADMIN_REQUEST = 'GET_VIDEOS_FOR_ADMIN_REQUEST'
export const GET_VIDEOS_FOR_ADMIN_SUCCESS = 'GET_VIDEOS_FOR_ADMIN_SUCCESS'
export const GET_VIDEOS_FOR_ADMIN_FAIL = 'GET_VIDEOS_FOR_ADMIN_FAIL'

export const FILTER_RESULTS_FOR_ADMIN_REQUEST = 'FILTER_RESULTS_FOR_ADMIN_REQUEST'
export const FILTER_RESULTS_FOR_ADMIN_SUCCESS = 'FILTER_RESULTS_FOR_ADMIN_SUCCESS'
export const FILTER_RESULTS_FOR_ADMIN_FAIL = 'FILTER_RESULTS_FOR_ADMIN_FAIL'

export const SEARCH_RESULTS_FOR_ADMIN_REQUEST = 'SEARCH_RESULTS_FOR_ADMIN_REQUEST'
export const SEARCH_RESULTS_FOR_ADMIN_SUCCESS = 'SEARCH_RESULTS_FOR_ADMIN_SUCCESS'
export const SEARCH_RESULTS_FOR_ADMIN_FAIL = 'SEARCH_RESULTS_FOR_ADMIN_FAIL'

export const GET_VIDEOS_LENGTH_REQUEST = 'GET_VIDEOS_LENGTH_REQUEST'
export const GET_VIDEOS_LENGTH_SUCCESS = 'GET_VIDEOS_LENGTH_SUCCESS'
export const GET_VIDEOS_LENGTH_FAIL = 'GET_VIDEOS_LENGTH_FAIL'

export const GET_VIDEO_OFF_LENGTH_REQUEST = 'GET_VIDEOS_OFF_LENGTH_REQUEST'
export const GET_VIDEO_OFF_LENGTH_SUCCESS = 'GET_VIDEOS_OFF_LENGTH_SUCCESS'
export const GET_VIDEO_OFF_LENGTH_FAIL = 'GET_VIDEOS_OFF_LENGTH_FAIL'

export const VIDEOS_LENGTH_FOR_FILTER_REQUEST = 'VIDEOS_LENGTH_FOR_FILTER_REQUEST'
export const VIDEOS_LENGTH_FOR_FILTER_SUCCESS = 'VIDEOS_LENGTH_FOR_FILTER_SUCCESS'
export const VIDEOS_LENGTH_FOR_FILTER_FAIL = 'VIDEOS_LENGTH_FOR_FILTER_FAIL'

export const VIDEOS_LENGTH_FOR_SEARCH_REQUEST = 'VIDEOS_LENGTH_FOR_SEARCH_REQUEST'
export const VIDEOS_LENGTH_FOR_SEARCH_SUCCESS = 'VIDEOS_LENGTH_FOR_SEARCH_SUCCESS'
export const VIDEOS_LENGTH_FOR_SEARCH_FAIL = 'VIDEOS_LENGTH_FOR_SEARCH_FAIL'

export const UPLOAD_VIDEO_REQUEST = 'UPLOAD_VIDEO_REQUEST'
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS'
export const UPLOAD_VIDEO_FAIL = 'UPLOAD_VIDEO_FAIL'

export const ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST = 'ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST'
export const ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS = 'ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS'
export const ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL = 'ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL'

export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST'
export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS'
export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL'

export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST'
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS'
export const ADD_VIDEO_FAIL = 'ADD_VIDEO_FAIL'

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST'
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAIL = 'DELETE_VIDEO_FAIL'

export const GET_VIDEO_INFO_REQUEST = 'GET_VIDEO_INFO_REQUEST'
export const GET_VIDEO_INFO_SUCCESS = 'GET_VIDEO_INFO_SUCCESS'
export const GET_VIDEO_INFO_FAIL = 'GET_VIDEO_INFO_FAIL'

export const EDIT_VIDEO_REQUEST = 'EDIT_VIDEO_REQUEST'
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS'
export const EDIT_VIDEO_FAIL = 'EDIT_VIDEO_FAIL'

export const EDIT_VIDEO_THUMBNAIL_REQUEST = 'EDIT_VIDEO_THUMBNAIL_REQUEST'
export const EDIT_VIDEO_THUMBNAIL_SUCCESS = 'EDIT_VIDEO_THUMBNAIL_SUCCESS'
export const EDIT_VIDEO_THUMBNAIL_FAIL = 'EDIT_VIDEO_THUMBNAIL_FAIL'

export const GET_VIDEO_OFF_REQUEST = 'GET_VIDEO_OFF_REQUEST'
export const GET_VIDEO_OFF_SUCCESS = 'GET_VIDEO_OFF_SUCCESS'
export const GET_VIDEO_OFF_FAIL = 'GET_VIDEO_OFF_FAIL'