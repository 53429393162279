import React, {useEffect, useState} from 'react';
import { check_email, reset_password } from '../../actions/AuthActions';
import {useDispatch, useSelector} from 'react-redux'


function ResetPassword(props) {
  const [infoMessage, setInfoMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const [email, setEmail] = useState()
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const dispatch = useDispatch()

  const {checked_email, message} = useSelector(state=>state.checked_email)

  const {resetMessage, loadingReset} = useSelector(state=>state.reset_password)

  useEffect(()=>{
    if (message && message.length){
      setInfoMessage(message)
    }
  },[message])

  useEffect(()=>{
    if (resetMessage && resetMessage.length){
      setConfirmMessage(resetMessage)
    }
  },[resetMessage])

  const submitHandler = (e) => {

    if (!emailRegex.test(email)){
      setInfoMessage("Please, Enter Valid Email")
    } else {
      dispatch(reset_password(email))
    }

  }

  return (
    <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center justify-content-center"
    style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh"}}>
      <div className='container d-flex flex-column align-items-center justify-content-center'
          style={{gap:"1.5rem"}} >
      {
        confirmMessage && confirmMessage.length ? 
        <div className = "text-info" style={{fontSize:"1.5rem"}}>{confirmMessage}</div>:
        <>
          <div className = "text-info text-center fs-2 fw-light">Enter Your Email</div>
          {infoMessage && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{infoMessage}</div>  } 
          <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input" 
            type="email" 
            value = {email} 
            name = "email"
            placeholder="Email" 
            onChange = {(e) => {setEmail(e.target.value); setInfoMessage("")}} />
          <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"
            onClick={submitHandler}>Send</button>
        </>
      }
      </div>
    </div>
  )
 
}

export default ResetPassword;