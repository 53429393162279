import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { signup } from '../../actions/AuthActions';


const Registration = () =>{

    const [infoMessage, setInfoMessage] = useState()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmMessage, setConfirmMessage] = useState()
    const [checked, setChecked] = useState(false)
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const numberRegex = /^\+\d{6,13}$/i;

    const dispatch = useDispatch()

    const signupUser = useSelector(state => state.user)

    const { isAuthenticated, authErrorMessage, authConfirmMessage } = signupUser

    const {checked_email, message} = useSelector(state=>state.checked_email)

    useEffect(()=>{
      if (message && message.length){
        setInfoMessage(message)
      }
    },[message])

    useEffect(()=>{
      if(authErrorMessage && authErrorMessage.length){
        setInfoMessage(authErrorMessage)
      }
    },[authErrorMessage])


    const submitHandler = (e) => {
      e.preventDefault()
      if(!firstName || firstName.length < 2 || !lastName || lastName.length < 2 ){
        setInfoMessage("Please, fill required fields") 
      }
      else if(!password || password.length < 8){
        setInfoMessage("Please provide password at least 8 symbols")
      }
      else if (!emailRegex.test(email)){
        setInfoMessage("Please, Enter Valid Email")
      } else if (!numberRegex.test(mobileNumber)){
        setInfoMessage("Please, enter valid mobile number with country code")
      }
      else if (password !== confirmPassword){
        setInfoMessage('Passwords do not match')
      } else {
        dispatch(signup(firstName, lastName, email, password, confirmPassword, mobileNumber, checked))
      }
    }

    // if (isAuthenticated){
    //     return <Navigate to = '/home'/>
    //   } else 
    if(authConfirmMessage && authConfirmMessage.length) {
        return(
          <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center"
            style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, fontSize:"1.5rem"}}>
            <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className = "text-info">{authConfirmMessage}</div>
            </div>
          </div>
        )
      } else {
        return (
          <div className = "container-fluid min-vh-100 d-flex align-items-center"
          style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0}}>
            <div className='container d-flex flex-column align-items-center justify-content-center'
              style={{gap:"1.5rem"}}>
              <div className = "text-info fs-4 fw-bold">Registration</div>
                {infoMessage && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{infoMessage}</div>}
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="text" value = {firstName}  
                    placeholder="First Name" 
                    name = "firstName"
                    onChange = {(e) => {setFirstName(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="text" 
                    value = {lastName} 
                    name = "lastName"
                    placeholder="Last Name" 
                  onChange = {(e) => {setLastName(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="email" 
                    value = {email} 
                    name = "email"
                    placeholder="Email" 
                  onChange = {(e) => {setEmail(e.target.value); setInfoMessage("")}} />

                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                  type="tel" 
                  value = {mobileNumber} 
                  name = "phone"
                  placeholder="Phone" 
                  onChange = {(e) => {setMobileNumber(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="password" 
                    value = {password}
                    name = "password"
                    placeholder="Password" 
                    autoComplete="off"
                  onChange = {(e) => {setPassword(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="password" 
                    value = {confirmPassword} 
                    name = "newPasswordConfirm"
                    placeholder="Confirm Password" 
                    autoComplete="off"
                  onChange = {(e) => {setConfirmPassword(e.target.value); setInfoMessage("")}} />
                <div className = "col-10 col-md-8 col-lg-4  d-flex flex-row align-items-center" >
                  <input type="checkbox" 
                      style={{accentColor:"#029eb0"}}
                      checked = {checked}
                      onChange={()=>setChecked(!checked)}/>
                      <div className = "text-info ps-2" id = "check_text" 
                      >I agree to get promotional emails from Cugate 
                      about new playlists and content</div>
                </div>
                <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"
                onClick={submitHandler}>Sign Up</button>
            </div>
          </div>
        )
      }
}

export default Registration;