import axios from 'axios'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Notification from '../app-main-content/playlistAdds/Notification'

import {
    GET_BANNER_VIDEOS_REQUEST,
    GET_BANNER_VIDEOS_SUCCESS,
    GET_BANNER_VIDEOS_FAIL,

    BANNER_PLAYLISTS_REQUEST,
    BANNER_PLAYLISTS_SUCCESS,
    BANNER_PLAYLISTS_FAIL,

    BANNER_THEMES_REQUEST,
    BANNER_THEMES_SUCCESS,
    BANNER_THEMES_FAIL,

    ADD_BANNER_VIDEO_REQUEST,
    ADD_BANNER_VIDEO_SUCCESS,
    ADD_BANNER_VIDEO_FAIL, 

    DELETE_BANNER_VIDEO_REQUEST,
    DELETE_BANNER_VIDEO_SUCCESS,
    DELETE_BANNER_VIDEO_FAIL, 

    EDIT_BANNER_VIDEO_REQUEST,
    EDIT_BANNER_VIDEO_SUCCESS,
    EDIT_BANNER_VIDEO_FAIL, 

    ARRANGE_BANNER_VIDEOS_REQUEST,
    ARRANGE_BANNER_VIDEOS_SUCCESS,
    ARRANGE_BANNER_VIDEOS_FAIL, 
} from '../constants/BannerConstants'


export const getBannerVideos = () => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_BANNER_VIDEOS_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_banner_videos/`, config
        )

        dispatch({
          type: GET_BANNER_VIDEOS_SUCCESS,
          payload: res.data
        })

    } catch(error) {
        dispatch({
        type: GET_BANNER_VIDEOS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getBannerPlaylists = (video_id) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type:BANNER_PLAYLISTS_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlists_for_banner/${video_id}/`, config
        )

        dispatch({
          type:BANNER_PLAYLISTS_SUCCESS,
          payload: res.data
        })

    } catch(error) {
        dispatch({
        type:BANNER_PLAYLISTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getBannerThemes = (playlist_id) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type:BANNER_THEMES_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_themes_for_banner/${playlist_id}/`, config
        )

        dispatch({
          type:BANNER_THEMES_SUCCESS,
          payload: res.data
        })

    } catch(error) {
        dispatch({
        type:BANNER_THEMES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const addBannerVideo = (data) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type:ADD_BANNER_VIDEO_REQUEST
        })

        const res  = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/add_banner_video/`, data, config
        )

        dispatch({
          type:ADD_BANNER_VIDEO_SUCCESS,
          payload: res.data
        })

    } catch(error) {
        dispatch({
        type:ADD_BANNER_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const deleteBannerVideo = (bannervideo_id) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type:DELETE_BANNER_VIDEO_REQUEST
        })

        const res  = await axios.delete(
            `${process.env.REACT_APP_API_URL}/cuviewTV/delete_banner_video/${bannervideo_id}/`, config
        )

        dispatch({
          type:DELETE_BANNER_VIDEO_SUCCESS,
          payload: res.data,
          bannervideo_id: bannervideo_id
        })

    } catch(error) {
        dispatch({
        type:DELETE_BANNER_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const editBannerVideo = (bannervideo_id, bannerTitle, status, imageFile) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: EDIT_BANNER_VIDEO_REQUEST
        })

        const res  = await axios.put(
            `${process.env.REACT_APP_API_URL}/cuviewTV/edit_banner_video/${bannervideo_id}/`, 
            { 'data' : JSON.stringify({'title': bannerTitle, 'status': status}), 'file':imageFile},
            config
        )

        dispatch({
        type: EDIT_BANNER_VIDEO_SUCCESS,
        payload: res.data,
        video_id: bannervideo_id,
        title: bannerTitle,
        status: status
        })

        Store.addNotification({
            content: <Notification message = {"Video has been Edited"}/>,
            type: 'custom',
            container: "bottom-left",
            insert: "top",
            dismiss: {
                duration: 3000,
                pauseOnHover: true
            }
            })


    } catch(error) {
        dispatch({
        type: EDIT_BANNER_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const arrangeBannerVideos = ( pos1, pos2) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }
  
  
    try {
      dispatch({
        type: ARRANGE_BANNER_VIDEOS_REQUEST
      })
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/arrange_banner_videos/${pos1}/${pos2}/`, config
        )
  
      dispatch({
        type: ARRANGE_BANNER_VIDEOS_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: ARRANGE_BANNER_VIDEOS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }
  