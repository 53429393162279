import React from 'react';

function FilterComponent(props) {
    return (
        <div className='d-flex px-2 flex-column color-secondary align-items-center filter-component'>
            <div className = "col-12 h-100">
                <div className = 'h-25'>
                    <div className = "fw-bold text-secondary">{props.title}</div>
                    <div></div>
                </div>
                <div className='py-2 h-75' >
                    <div className = 'h-100 col-12 bg-white rounded px-2 d-flex align-items-center overflow-hidden input-wrapper'>
                        <input 
                            className = "col-11 py-2 h-100 fw-light bg-white border-0 text-secondary ps-1"
                            placeholder='Write or Select'
                            onChange={(e)=>props.setValue(e.target.value)}
                            onClick={()=>{props.setActive(true)}}
                            value = {props.value}/>
                        <img className = "col-1" src = {""} alt = ""/>
                    </div>
                </div>
                {
                    props.isActive ?
                    <div className = "col-12 position-relative d-flex justify-content-end" 
                        style={{userSelect:"none"}} ref = {props.componentRef}>
                        <div className = "w-100 position-absolute mt-1 text-dark p-2 bg-white shadow rounded" 
                            style = {{zIndex:1000}}>
                            <div className='addToplaylist-list-wrapper'
                                onScroll = {props.handleScroll}
                                style={{maxHeight:"20rem"}}
                            >
                                {
                                props.loading ? <>Loading...</> :
                                props.data && props.data.length ? props.data.map((item) => (
                                        <div
                                            className = "px-1 py-2 text-dark addSearchDropdown-item"
                                            role = "button"
                                            style={{transition:"all 0.2s", wordBreak:"normal", lineHeight:"1.125rem"}}
                                            key = {item.id}
                                            onClick = {()=>props.chooseHandler(item)}
                                        >{item.title.toLowerCase()}</div>
                                    )) : <div className='text-dark'>No result</div>
                                }
                            </div>
                        </div>
                    </div> : "" 
                }
            </div>
        </div>
    );
}

export default FilterComponent;