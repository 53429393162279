import React, {useState, useEffect} from 'react'
import Slider from '../slides/Slider'
import { useSelector, useDispatch } from 'react-redux'
import { listUserPlaylists } from '../../actions/PlaylistActions'



const Playlists = (props) => {

  const [message, setMessage] = useState()

  const dispatch = useDispatch()

  const userPlaylistsList = useSelector(state => state.userPlaylists)

  const {error, userPlaylistloading, userPlaylists } = userPlaylistsList

  const { user } = useSelector(state=>state.user)

  useEffect(()=>{
    if(user && user.id){
      dispatch(listUserPlaylists())
    }
  },[user])

  return (
    <>
      {
      userPlaylists && userPlaylists.length ?
        <div className='container-fluid bg-dark pb-4' ref = {props.myPlaylistsRef}>
          {
            userPlaylistloading ? <div>Loading...</div> :
            message ? <h3>{message}</h3> :
            <div className = 'col-12 d-flex flex-column justify-content-center align-items-center'>
              <div className = "col-10 px-1 px-lg-0 py-4">
                  <h3 className = "px-0 text-info fs-1" style = {{fontWeight:"500", whiteSpace:"wrap"}}>MY PLAYLISTS</h3>
                  </div>
                    <Slider
                      num = {5}
                      type = {1}
                      showTitle = {true}
                      tvData={userPlaylists}
                      slideClickHandler = {props.slideClickHandler}
                  />
                </div>
          }
        </div> : "" 
      }
    </>
  )
}

export default Playlists;

