import React from 'react'
import back from "../icons/back.svg"
import next from "../icons/next.svg"

export default function Arrow({direction, handleClick}) {
  let xOffset = direction === 'back' ? '-4%' : '101%'

  return (
    <div
    className="Arrow"
    onClick={handleClick}
    style={{
      cursor: 'pointer',
      display: "block",
      alignItems: 'center',
      justifyContent: "center",
      // width: '1.8vw',
      // height: '1.15vw',
      // top: `calc(100%/2 - .9vw)`,
      left: xOffset,
      overflow: 'hidden',
      zIndex: '1'
    }}
    >
      {direction === 'back' ? <img src={back} alt="Left Arrow" style={{height:"1.979vw"}} />  : <img src={next} alt="Right Arrow" style={{height:"1.979vw"}} />}
    </div>
  );
}


      // position: "absolute",
