import React, {useState, useEffect} from 'react';
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom';
import play_btn from '../icons/play_btn.svg'
import { useSelector, useDispatch } from 'react-redux';
import { getVideosForBanner } from '../../actions/videoActions';

function Banner(props) {

    const [bannerRef, setBannerRef] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getVideosForBanner())
    },[dispatch])

    const {videosForBanner} = useSelector(state => state.videosForBanner)


    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        // speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        inisialSlide: 0,
        mobileFirst:true,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
              }
            } ]

        
      };

      const watchHandler = (item) => {
        localStorage.setItem('video_id', item.video)
        localStorage.setItem('playlist_id', item.playlist); 
        localStorage.setItem("group_id", item.group); 
        if(props.user){
            navigate(`/home?g=${item.group}&p=${item.playlist}&v=${item.video}`)
        } else {
            navigate('/login')
        }
      }

    return (
        <div id="banner_wrapper">
        <div id="banner">
                <Slider {...settings} ref={setBannerRef} slickGoTo>
                    { videosForBanner && videosForBanner.length ?
                        videosForBanner.map((item, index) => (
                            <div key = {index} className="banner_slide">
                                <img className = 'banner_image' alt = "" src={`https://img.cugate.com/?o=CUTV_VIDEO&i=${item.video}&s=mega`} />
                                <div className="px-5 px-lg-5 mt-3 mt-md-0 banner_content">
                                    <div className="swiper-button-prev" onClick={bannerRef?.slickPrev}></div>
                                        <div className="mx-0 mx-lg-5 slide-title">{item.title}</div>
                                        <div className="mx-0 mx-lg-5 btn btn-primary slide_watchbtn" onClick={()=>watchHandler(item)}>
                                            <img src={play_btn} alt = ""/>
                                            <span>Watch now</span>
                                        </div>
                                    <div className="swiper-button-next" onClick={bannerRef?.slickNext}></div>
                                </div>
                            </div>
                        )) : ''
                    }
                </Slider>
            </div>
    </div>
    );
}

export default Banner;