import React from 'react'
import {useSearchParams} from 'react-router-dom'
import { Store } from 'react-notifications-component'
import Notification from '../playlistAdds/Notification'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Copy from '../icons/Copy.svg'
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";


const ShareContent = (props) => {

    const [searchParams, setSearchParams] = useSearchParams()

    let videoId= props.video.id
    let playlist_id = parseInt(searchParams.get('p')) ? parseInt(searchParams.get('p')) : localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 54
    let group_id = parseInt(searchParams.get('g')) ? parseInt(searchParams.get('g')) : localStorage.getItem('group_id') ? localStorage.getItem('group_id') : 4  

    const url = `${process.env.REACT_APP_API_URL}/cuviewTV/share/${videoId}/${playlist_id}/${group_id}/`

    const urlForCopy = encodeURI(`${process.env.REACT_APP_API_URL}/home?g=${group_id}&p=${playlist_id}&v=${videoId}`) 
    
    const copyHandler = () => {
        Store.addNotification({
            content: <Notification message = {"Copied To Clipboard"}/>,
            type: 'custom',
            container: "bottom-center",
            insert: "top",
            dismiss: {
              duration: 2000,
              pauseOnHover: true
            }
          })
        }
    

    return (
        <div className = "d-flex justify-content-center justify-content-lg-end bg-white p-2 rounded"
            style={{gap: "0.5rem"}}
        >
            <div className = "">
                <CopyToClipboard text={urlForCopy}>
                    <img src = {Copy} style = {{cursor:"pointer"}} alt = "" onClick={() => copyHandler()}/>
                </CopyToClipboard>
            </div>
            <div className = "">
                <FacebookShareButton url = {url}>
                    <FacebookIcon size = {32} style={{borderRadius:"0.5vw"}}/>  
                </FacebookShareButton>
            </div>
            <div className = "">
                <FacebookMessengerShareButton url = {url}>
                    <FacebookMessengerIcon size = {32} round = {true}/>  
                </FacebookMessengerShareButton>
            </div>
            <div className = "px-1">
                <TwitterShareButton url = {url}>
                    <TwitterIcon size = {32} style={{borderRadius:"0.5vw"}}/>
                </TwitterShareButton>
            </div>
            <div className = "px-1">
                <WhatsappShareButton url = {url}>
                    <WhatsappIcon size = {32} style={{borderRadius:"0.5vw"}}/>  
                </WhatsappShareButton>
            </div>
            <div className = "">
                <TelegramShareButton url = {url}>
                    <TelegramIcon size = {32} style={{borderRadius:"0.5vw"}}/>  
                </TelegramShareButton>
            </div>
            <div className = "">
                <LinkedinShareButton url = {url}>
                    <LinkedinIcon size = {32} style={{borderRadius:"0.5vw"}}/>
                </LinkedinShareButton>
            </div>

        </div>
    )
}

export default ShareContent;
