import React, {useState} from 'react';
import Attach from '../adminIcons/Attach.svg'
import { useDispatch } from 'react-redux';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import { addPlaylistForAdmin } from '../../actions/AdminPlaylistsActions';
import { useNavigate } from 'react-router-dom'
import BackIcon from '../adminIcons/BackIcon.svg'


const AddAdminPlaylist = (props) => {

    const [imageFile, setImageFile] = useState()
    const [imageURL, setImageUrl] = useState()
    const [statusOn, setStatusOn] = useState(0)
    const [title, setTitle] = useState("")
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleImage = (e) => {
        setImageFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        setImageUrl(u)
        props.setChanges(true)
    }

    const addPlaylistHandler = () => {
        if(!title.length){
            alert('Please, Provide Title')
        } else if (!imageFile){
            var answer = window.confirm("Upload without Thumbnail?");
            if (answer) {
                dispatch(addPlaylistForAdmin(title, statusOn, ' '))
                props.setChanges(false)
                navigate('/adminPlaylists')
            }
        } else {
            dispatch(addPlaylistForAdmin(title, statusOn, imageFile ))
            props.setChanges(false)
            navigate('/adminPlaylists')
        }
    }


    const backHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }

    return (
        <div className = "col-10 p-4" style={{marginLeft:"18rem", minHeight:'100vh'}}>
            <div className='d-flex align-items-center pb-4'>
                <img 
                    src = {BackIcon} 
                    role = 'button'
                    onClick={backHandler}
                    alt = ""/>
                <div className = "fw-bold fs-7 ps-4 text-secondary" 
                    role='button' 
                >PAYLISTS</div>
            </div>
            <div className = "w-100 d-flex pb-5">
                <div className = "col-7 bg-success me-3 rounded px-3 py-5">
                    <div className = "text-secondary fw-bold pb-2">Title*</div>
                    <input
                        type="text"
                        className="col-12 rounded fs-5 border-0 bg-warning text-white py-3 search-input ps-3"
                        onChange = {(e)=>{setTitle(e.target.value); props.setChanges(true)}}
                        value = {title}
                    />
                    <div className = "py-4" role = "button">
                    {
                        statusOn === 0 ? 
                        <div className = "d-flex align-items-center">
                            <img src = {SwitchOff} alt = "" onClick={()=>{setStatusOn(1); props.setChanges(true)}}/>
                            <div className = "text-info ms-4">Access OFF</div>
                        </div>
                            : 
                            <div className = "d-flex align-items-center">
                            <img src = {SwitchOn} alt = "" onClick={()=>{setStatusOn(0); props.setChanges(true)}}/>
                            <div className = "text-info ms-4">Access ON</div>
                        </div>
                    }
                    </div>
                    <div className = "w-100 d-flex justify-content-end">
                        <div 
                            className = "text-white px-5 py-2 bg-secondary rounded-pill"
                            role = "button"
                            onClick = {()=>addPlaylistHandler()}
                        >Save</div>
                    </div>
                </div>
                <div className = "col-3 bg-success rounded p-2">
                    <div className = "bg-warning p-2 mt-3 rounded">
                        <div className = "text-info pb-2">Upload Thumbnail</div>
                        {
                            imageFile && imageFile.name ? 
                            <>
                                <img src = {imageURL} alt = ""
                                    style = {{width:"100%", aspectRatio:"16/9"}}
                                />
                                <div className = "w-100 d-flex justify-content-between align-items-center mt-3">
                                    <div className = "text-info w-75 text-decoration-underline text-wrap overflow-hidden">{imageFile.name}</div>
                                    <input 
                                        id = "reimage-input"
                                        type="file" 
                                        name="imageFile"
                                        accept='image/jpeg, image/png, image/jpg'
                                        onChange={handleImage}
                                        style={{display:"none"}}
                                        />
                                        <label for="reimage-input">
                                            <div 
                                                className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                                role='button'
                                            >Change</div>
                                        </label>
                                </div>
                            </>
                            : 
                            <div className = "w-100 px-3 py-5 bg-warning rounded">
                                <div className = "w-100 py-5 d-flex bg-secondary justify-content-center">
                                    <input 
                                        id = "image-input"
                                        type="file" 
                                        name="imageFile"
                                        accept='image/jpeg, image/png, image/jpg'
                                        onChange={handleImage}
                                        style={{display:"none"}}
                                        />
                                    <label for="image-input">
                                        <img src = {Attach} alt = "" role="button"/>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdminPlaylist;