import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player/lazy'
import MultiRangeSlider from "multi-range-slider-react";
import Play from '../adminIcons/Play.svg'
import Forward from '../adminIcons/Forward.svg'
import Backward from '../adminIcons/Backward.svg'

const TrackTimes = (props) => {

    const [videoRef, setVideoRef] = useState(0)

    const [minValue, set_minValue] = useState(0);
    const [maxValue, set_maxValue] = useState();
    const [playing, setPlaying] = useState(false)
    const [position, setPosition] = useState(1)

    let fullTrack = {}

    const handleInput = (e) => {
        if(props.addedTracks && props.addedTracks.length ){
            setPosition(props.addedTracks[0].position+1)
            if(props.addedTracks[0].end_time <= e.minValue){
                set_minValue(e.minValue);
            } else {
                alert("Value Shouldn't override previous Track")
            }
        } else {
            set_minValue(e.minValue);
        }
        set_maxValue(e.maxValue);
    };


    useEffect(()=>{
        if(props.addedTracks && props.addedTracks.length>0){
            set_minValue(props.addedTracks[0].end_time)
        }
    },[])


    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    const backWardHandler = () => {
        if(minValue < videoRef?.getCurrentTime()-10){
            videoRef?.seekTo(videoRef?.getCurrentTime() - 10)
        } else {
            videoRef?.seekTo(minValue)
        }
    }

    const forWardHandler = () => {
        if(maxValue > videoRef?.getCurrentTime()+10){
            videoRef?.seekTo(videoRef?.getCurrentTime()+10)
        } else {
            videoRef?.seekTo(maxValue)
        }
    }

    const playClickhandler = () => {
        videoRef?.seekTo(minValue)
        setPlaying(true)
    }

    const handleProgress = (changeState) => {
        if(changeState.playedSeconds > maxValue){
            setPlaying(false)
        }
    }

    const resetHandler = () => {
        if(props.addedTracks && props.addedTracks.length){
            set_minValue(props.addedTracks[0].end_time)
        } else {
            set_minValue(0)
        }
        set_maxValue(videoRef?.getDuration())
    }

    const handleSave = () => {
        fullTrack.album_title = props.album.title
        fullTrack.catalogue_num = props.album.catalogue_num
        fullTrack.album_id  = props.album.id
        fullTrack.track_id = props.track.id
        fullTrack.track_title = props.track.title
        fullTrack.genre = props.track.genre
        fullTrack.artists = props.track.artists
        fullTrack.composers = props.track.composers
        fullTrack.start_time = Math.round(minValue)
        fullTrack.end_time = Math.round(maxValue)
        fullTrack.position = position
        props.setAddedTracks(prev=>[fullTrack, ...prev])
        props.setIsActive(false)
    }

    return (
        <div className = "d-flex flex-column align-items-center">
            <div className='d-flex align-items-center pb-4'>
                <div className = "fs-7 text-primary pe-3" style={{fontWeight:"800"}}>ADD TRACK</div>
                <div className='text-primary' style={{fontWeight:"500"}}>{props.track.title}</div>
            </div>
            <ReactPlayer
              ref = {setVideoRef}
              className='react-player'
              controls
              url={ props.videoUrl }
              onProgress={handleProgress}
              onReady={()=>set_maxValue(videoRef?.getDuration())}
              playing = {playing}
              config={{
                file: { 
                  attributes: {
                    controlsList: 'nodownload nofullscreen'
                  }
                }
                }}
              width="600px"
            />  
            <div className = "pt-5" style={{width:"600px"}}>
                {  maxValue &&
                    <>
                        <MultiRangeSlider
                            min={0}
                            max={videoRef?.getDuration()}
                            step={1}
                            minValue={minValue}
                            maxValue={maxValue}
                            minCaption={toTimeString(minValue)}
                            maxCaption={toTimeString(maxValue)}
                            ruler = 'false'
                            label = 'false'
                            style={{border:'none', boxShadow:"none"}}
                            barLeftColor='#011A33'
                            barRightColor='#011A33'
                            barInnerColor='#00C8D5'
                            thumbLeftColor='#FFCD00'
                            thumbRightColor='#FFCD00'
                            onInput={(e) => {
                                handleInput(e);
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ margin: '10px' }}>duration:</div>
                            <div style={{ margin: '10px' }}>{toTimeString(minValue)}</div>
                            <div style={{ margin: '10px' }}>{toTimeString(maxValue)}</div>
                        </div>
                    </>
                }
            </div>
            <div className = "d-flex justify-content-between" style={{width:"600px"}}>
                <div 
                    className = "bg-transparent rounded-pill border border-secondary text-secondary px-5 py-2"
                    role = 'button'
                    onClick={()=>resetHandler()}
                    >Reset</div>
                <div className = "bg-primary py-2 px-5 rounded-pill">
                    <img 
                        className='pe-4' 
                        src = {Backward} 
                        role = 'button'
                        onClick={()=>backWardHandler()}
                        alt = ""/>
                    <img 
                        src = {Play} 
                        role = 'button'
                        onClick={()=>playClickhandler()}
                        alt = "" />
                    <img 
                        className='ps-4' 
                        src = {Forward} 
                        role = 'button'
                        onClick={()=>forWardHandler()}
                        alt = ""/>
                </div>
                <div 
                    className = "bg-secondary rounded-pill text-white px-5 py-2"
                    role = 'button'
                    onClick={()=>handleSave()}
                    >Save</div>
            </div>
        </div>
    );
};

export default TrackTimes;