import React, {useRef, useEffect, useState} from 'react'
import SidePlaylist from './SidePlaylist'
import ReactPlayer from 'react-player/lazy'
import VideoNameAndActions from './VideoNameAndActions'
import { useSelector } from 'react-redux'
import { getAnalyzeInfo } from '../../actions/AnalyzeActions'
import { useDispatch } from 'react-redux'
import PlayerHeader from './PlayerHeader'
import Back from '../icons/BACK_but.svg'
import Next from '../icons/NEXT_but.svg'
import Play from '../icons/PLAY_but.svg'
import Pause from '../icons/POUSE_but.svg'
import screenfull from "screenfull"
import FullScreen from '../icons/FullScreen.svg'
import Minimize from '../icons/Minimize.svg'
import AnalyzeButton from './AnalyzeButton'
import useWindowDimentions from '../useWindowDimentions'
import Slider from '../slides/Slider'
import QRCode from "react-qr-code";


export default function VideoPlayer(props) {

  const { playlistVideos } = useSelector(state => state.userPlaylists)

  const videoPlayerRef = useRef()

  const playerContainerRef = useRef()

  const dispatch = useDispatch()

  const [playing, setPlaying] = useState(false)

  const [menuVisible, setMenuVisible] = useState(true)

  const [mouse, setMouse] = useState(false)

  const [full, setFull] = useState(false)

  const [analyzeBottom, setAnalyzeBottom] = useState(false)

  const {width, height} = useWindowDimentions()

  const { watermark_info } = useSelector(state=>state.analyze_info)

  const [qr, setQr] = useState(false)

  let time = 0

  const [device, setDevice] = useState(false) 

  useEffect(()=>{
    if(width < 990){
      setAnalyzeBottom(true)
    } else {
      setAnalyzeBottom(false)
    }
    if(height > width){
      setDevice(true)
    }
  },[width])


  useEffect(()=>{
    const identifier = setTimeout(() => {
        setQr(!qr)
    }, 15000);

    return () => {
        clearTimeout(identifier)
    }

  },[qr])

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current)
    setFull(!full)
}

  function exitHandler() {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        setFull(false)
    }
  }

  if (document.addEventListener) {
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
  }

  useEffect(()=>{
    videoPlayerRef.current && videoPlayerRef.current.scrollIntoView({ behavior: "smooth" })
  },[playlistVideos])

  const endHandler = () => {
    if (playlistVideos && playlistVideos.length){
      let index = playlistVideos.findIndex(data => data.id === props.video.id);
      if(index < playlistVideos.length-1){
        props.setVideo(playlistVideos[index+1])
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[index+1].id})
        localStorage.setItem('video_id', playlistVideos[index+1].id)
      }
    }
  }

  const prevHandler = () => {
    if (playlistVideos && playlistVideos.length){
        let index = playlistVideos.findIndex(data => data.id === props.video.id);
        if(index > 0){
          props.setVideo(playlistVideos[index-1])
          props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[index-1].id})
          localStorage.setItem('video_id', playlistVideos[index-1].id)
        }
      }
    setPlaying(true)
  }

  const startHandler = () => {
    setTimeout(()=>{
      if(props.playerRef && props.playerRef.current && props.video) {
        let t = Math.round(props.playerRef.current.getCurrentTime())
        if(props.video){
          if (props.video.duration-30<t){
              time = Math.floor((props.video.duration-30)/5)*5
          } else {
              time = Math.floor(t/5)*5
          }
          dispatch(getAnalyzeInfo(props.video.id, time))
        }
      }
    }, 4500)
  }

  let mouseMove = (e) => {
    setMenuVisible(true)
    setMouse(true)
    setTimeout(() => {
        setMouse(false); 
    }, 500)
}

useEffect(()=>{
    if(!mouse){
        const identifier = setTimeout(() => {
            setMenuVisible(false)
        }, 1800);

        return () => {
            clearTimeout(identifier)
        }
    }
  },[mouse])

  useEffect(()=>{setPlaying(true)},[props.video])

  const slideClickHandler = (v) => {
    props.setVideo(v)
    localStorage.setItem('video_id', v.id)
    props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:v.id})
  }

  return (
    <div className = 'container-fluid position-relative bg-primary pb-5 d-flex flex-column justify-content-center align-items-center px-1 px-lg-5' ref = {videoPlayerRef}>
      <PlayerHeader
        setVideo = {props.setVideo}
        searchParams = {props.searchParams}
        setSearchParams = {props.setSearchParams}
      />
      <div className = "col-12 position-relative col-lg-11 d-flex flex-column flex-lg-row align-items-start px-1 justify-content-center justify-content-lg-between">
      {
        !analyzeBottom &&       
        <AnalyzeButton 
          handleRewind = {props.handleRewind}
          video = {props.video}
        />
      }
        <div className="col-12 col-lg-8 d-flex flex-column pe-0 pe-lg-4 "
        >
          <div className = 'position-relative'
            ref = {playerContainerRef}
            onMouseMoveCapture={mouseMove}
            onMouseLeave={()=>setMenuVisible(false)}
            onTouchStart={mouseMove}
            style={{aspectRatio:"16/9"}}
          >
            <ReactPlayer
              ref = {props.playerRef}
              className='react-player'
              controls
              url={ props.video && props.video.file_path_windows ? 'https://prelistening.cugate.com/cloud_audiofiles/' + props.video.file_path_windows.slice(3, props.video.file_path_windows.length) : ''}
              onEnded = {endHandler}
              onStart={()=>startHandler()}
              onPlay={()=>setPlaying(true)}
              onPause={()=>setPlaying(false)}
              playing = {playing}
              // light = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=600`}
              config={{
                file: { 
                  attributes: {
                    controlsList: 'nodownload nofullscreen'
                  }
                }
                }}
              width="100%"
              height='100%'
            />               
            {
              <div className={ !playing || menuVisible ? 
                'position-absolute d-flex justify-content-center align-items-center visible':
                'position-absolute d-flex justify-content-center align-items-center hidden'
              }
                style={{zIndex:1000, top:"48%", left:"50%", transform:"translate(-50%,-50%)", gap:"1rem"}}>
                <div role = "button"
                  style={{width:"50px"}}
                ><img src = {Back} onClick={prevHandler}/></div>
                {
                  playing ? 
                  <div role = "button" style={{width:"80px"}} onClick={()=>setPlaying(false)}><img src = {Pause}/></div> :
                  <div role = "button" style={{width:"80px"}} onClick={()=>setPlaying(true)}><img src = {Play}/></div> 
                }
                <div role = "button" style={{width:"50px"}} onClick={endHandler}><img src = {Next}/></div>
              </div> 
            }
            {
             full && watermark_info && watermark_info.result_link && width > 990? 
              <div className={!playing || qr ? 
                'position-absolute rounded mx-auto mx-md-0 mx-lg-0 visible': 
                'position-absolute rounded mx-auto mx-md-0 mx-lg-0 hidden'}
                style={{zIndex:1000, width:"256px", top:"30px", left:"30px", height: "auto", background:"white", padding:"0.7vw"}}
              >
              <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={watermark_info.result_link}
                  viewBox={`0 0 256 256`}
              />
              </div> : "" 
            }
            {
              full ? <div className={!playing || menuVisible ? 'col-12 px-4 position-absolute visible px-1' : ' col-12 px-4 position-absolute hidden'}
                style={{bottom:"100px"}}>
                <Slider
                    num = {6}
                    showTitle = {false}
                    type = {2}
                    tvData={playlistVideos}
                    slideClickHandler={slideClickHandler}
                />
              </div> : ""
            }
            {
              full ? 
              <div className={!playing || menuVisible ? 'position-absolute visible' : 'position-absolute hidden'}
                role = 'button'
                style={ full ? device ? {right:"63px", bottom:"38px"} : {right:"100px", bottom:"44px"} : {right:"100px", bottom:"44px"}}
                onClick={()=>toggleFullScreen()}
              >
                <img style={full ? device ? {width:"15px"} : {width:"23px"}: {width:"23px"}} src = {Minimize}/>
              </div> :
                <div className={!playing || menuVisible ? 'position-absolute visible' : 'position-absolute hidden'}
                  role='button'
                  style={{right:"64.8px", bottom:"36px"}}
                  onClick={()=>toggleFullScreen()}
                >
                  <img style={{width:"14px"}} src = {FullScreen}/>
              </div> 
            }
          </div>
          <div className = 'd-flex mt-2 pb-4 pb-lg-0 position-relative'>
            {
              analyzeBottom &&
              <AnalyzeButton 
                handleRewind = {props.handleRewind}
                video = {props.video}
            />
            }
              <VideoNameAndActions  
                video = {props.video}
              />
            </div>
          </div>
          <div className = "col-12 col-lg-3 pt-3 pt-md-0">
            <SidePlaylist
              setVideo = {props.setVideo}
              searchParams = {props.searchParams}
              setSearchParams = {props.setSearchParams}
            />
          </div>
        </div>
      </div>
  )

}

