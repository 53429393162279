import React, {useState} from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { verify } from '../../actions/AuthActions';

function UserActivate(props) {

    const [infoMessage, setInfoMessage] = useState('')
    const [verified, setVerified] = useState(false)
    const {uid, token} = useParams()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const verify_account = () => {
      dispatch(verify(uid, token))
      setVerified(true)
      navigate('/login')
    }


    if(verified){
      return <Navigate to = '/home'/>
    } else {
    return (
        <div>
            {
              <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center justify-content-center"
                style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh", gap:"1.5rem"}}
                >
                    <div className = "text-info fs-4">Activate Your Account</div>
                    <button className="col-8 col-md-5 col-lg-3 text-primary bg-info fw-bold border-0 rounded py-2"                       
                      onClick={()=>verify_account()}
                    >ACTIVATE</button>
                </div>
            }
            
        </div>
    );
  }
}


export default UserActivate;

