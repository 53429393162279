import axios from 'axios'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Notification from '../app-main-content/playlistAdds/Notification'

import {
    GET_VIDEO_REQUEST,
    GET_VIDEO_SUCCESS, 
    GET_VIDEO_FAIL,

    VIDEOS_FOR_BANNER_REQUEST,
    VIDEOS_FOR_BANNER_SUCCESS,
    VIDEOS_FOR_BANNER_FAIL,

    VIDEO_CLICKS_REQUEST,
    VIDEO_CLICKS_SUCCESS,
    VIDEO_CLICKS_FAIL,

    GET_VIDEOS_FOR_ADMIN_REQUEST,
    GET_VIDEOS_FOR_ADMIN_SUCCESS,
    GET_VIDEOS_FOR_ADMIN_FAIL,

    FILTER_RESULTS_FOR_ADMIN_REQUEST,
    FILTER_RESULTS_FOR_ADMIN_SUCCESS,
    FILTER_RESULTS_FOR_ADMIN_FAIL,    

    SEARCH_RESULTS_FOR_ADMIN_REQUEST,
    SEARCH_RESULTS_FOR_ADMIN_SUCCESS,
    SEARCH_RESULTS_FOR_ADMIN_FAIL,

    GET_VIDEOS_LENGTH_REQUEST,
    GET_VIDEOS_LENGTH_SUCCESS,
    GET_VIDEOS_LENGTH_FAIL,

    VIDEOS_LENGTH_FOR_FILTER_REQUEST,
    VIDEOS_LENGTH_FOR_FILTER_SUCCESS,
    VIDEOS_LENGTH_FOR_FILTER_FAIL,

    VIDEOS_LENGTH_FOR_SEARCH_REQUEST,
    VIDEOS_LENGTH_FOR_SEARCH_SUCCESS,
    VIDEOS_LENGTH_FOR_SEARCH_FAIL,

    UPLOAD_VIDEO_REQUEST,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAIL,

    ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST,
    ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
    ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,
    
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL,

    ADD_VIDEO_REQUEST,
    ADD_VIDEO_SUCCESS,
    ADD_VIDEO_FAIL,

    DELETE_VIDEO_REQUEST,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAIL,

    GET_VIDEO_INFO_REQUEST,
    GET_VIDEO_INFO_SUCCESS,
    GET_VIDEO_INFO_FAIL,

    GET_VIDEO_OFF_REQUEST,
    GET_VIDEO_OFF_SUCCESS,
    GET_VIDEO_OFF_FAIL,

    GET_VIDEO_OFF_LENGTH_REQUEST,
    GET_VIDEO_OFF_LENGTH_SUCCESS,
    GET_VIDEO_OFF_LENGTH_FAIL,

    EDIT_VIDEO_REQUEST,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAIL,

    EDIT_VIDEO_THUMBNAIL_REQUEST,
    EDIT_VIDEO_THUMBNAIL_SUCCESS,
    EDIT_VIDEO_THUMBNAIL_FAIL,

} from '../constants/videoConstants'

export const getVideosForBanner = () => async (dispatch, getState) => {
    try {
        dispatch({
        type: VIDEOS_FOR_BANNER_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_for_banner/`
        )

        dispatch({
        type: VIDEOS_FOR_BANNER_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: VIDEOS_FOR_BANNER_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getActiveVideos = (video_id) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_VIDEO_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_video/${video_id}/`
        )

        dispatch({
        type: GET_VIDEO_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const AddVideoClicks = (video_id) => async (dispatch, getState) => {
    try {
        dispatch({
        type: VIDEO_CLICKS_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/video_click_count/${video_id}/`
        )

        dispatch({
        type: VIDEO_CLICKS_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: VIDEO_CLICKS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getVideosForAdmin = (page, index) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_VIDEOS_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_for_admin/${page}/`, config
        )

        dispatch({
        type: GET_VIDEOS_FOR_ADMIN_SUCCESS,
        payload: data, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEOS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}



export const getVideosOff = (page, index) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_VIDEO_OFF_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_off/${page}/`, config
        )

        dispatch({
        type: GET_VIDEO_OFF_SUCCESS,
        payload: data, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEO_OFF_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const executeAdminFilter = (components, page, index) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: FILTER_RESULTS_FOR_ADMIN_REQUEST
        })

        const body = {data: components}

        const res  = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_filter_results_for_admin/${page}/`,
            body, config
        )

        dispatch({
        type: FILTER_RESULTS_FOR_ADMIN_SUCCESS,
        payload: res.data,
        page: page, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: FILTER_RESULTS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const executeAdminSearch = (keyword, page, index) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: SEARCH_RESULTS_FOR_ADMIN_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_search_results_for_admin/${keyword}/${page}/`, config
        )

        dispatch({
        type: SEARCH_RESULTS_FOR_ADMIN_SUCCESS,
        payload: res.data,
        page: page, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: SEARCH_RESULTS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideosLength = () => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_VIDEOS_LENGTH_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_length/`, config
        )

        dispatch({
        type: GET_VIDEOS_LENGTH_SUCCESS,
        payload: res.data.videoLength,
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEOS_LENGTH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideosOffLength = () => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_VIDEO_OFF_LENGTH_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_off_length/`, config
        )

        dispatch({
        type: GET_VIDEO_OFF_LENGTH_SUCCESS,
        payload: res.data.videoLength,
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEO_OFF_LENGTH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideosLengthForSearch = (keyword) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: VIDEOS_LENGTH_FOR_SEARCH_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_length_for_search/${keyword}/`, config
        )

        dispatch({
        type: VIDEOS_LENGTH_FOR_SEARCH_SUCCESS,
        payload: res.data.videoLength,
        })

    } catch(error) {
        dispatch({
        type: VIDEOS_LENGTH_FOR_SEARCH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideosLengthForFilter = (components) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    const body = {data: components}

    try {
        dispatch({
        type: VIDEOS_LENGTH_FOR_FILTER_REQUEST
        })

        const res  = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videos_length_for_filter/`, body, config
        )

        dispatch({
        type: VIDEOS_LENGTH_FOR_FILTER_SUCCESS,
        payload: res.data.videoLength,
        })

    } catch(error) {
        dispatch({
        type: VIDEOS_LENGTH_FOR_FILTER_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const uploadVideo = (file) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    const body = {file: file}

    try {
        dispatch({
        type: UPLOAD_VIDEO_REQUEST
        })

        const res  = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/upload_video/`, body, config
        )

        dispatch({
        type: UPLOAD_VIDEO_SUCCESS,
        payload: res.data,
        })

    } catch(error) {
        dispatch({
        type: UPLOAD_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })

        Store.addNotification({
            content: <Notification message = {"Video Upload Failed"}/>,
            type: 'custom',
            container: "bottom-left",
            insert: "top",
            dismiss: {
                duration: 3000,
                pauseOnHover: true
            }
            })
    }
}

export const addVideoToPlaylistForAdmin = (video_list, playlist) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }
  
        try {
            dispatch({
                type: ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_playlist_video_for_admin/${playlist.id}/`,
            {'video_list': video_list},
                config
            )

            dispatch({
                type: ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
                payload: res.data,
                playlist: playlist,
                video_list:video_list
            })
  
            Store.addNotification({
                content: <Notification message = {"Video has been added to playlist"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }
  
    }
  }
  

  export const deleteVideoFromPlaylistForAdminFromVideoPage = (video_id, playlist_id) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }
  
        try {
            dispatch({
                type: DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST
            })
    
            const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_video_for_admin/${video_id}/${playlist_id}/`,
                config
            )
  
            dispatch({
                type: DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS,
                payload: data,
                video_id: video_id,
                playlist_id: playlist_id
            })
  
            Store.addNotification({
                content: <Notification message = {"Video has been deleted from playlist"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }
  
    }
  }


  export const addVideo = (video_info, imageFile) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'multipart/form-data',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }
  
        try {
            dispatch({
                type: ADD_VIDEO_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/fill_video_info/`,
            { 'data' : JSON.stringify(video_info), 'file':imageFile},
                config
            )

            dispatch({
                type: ADD_VIDEO_SUCCESS,
                payload: res.data,
            })
  
            Store.addNotification({
                content: <Notification message = {"Video has been added"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:ADD_VIDEO_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })

        }
  
    }
  }

  export const deleteVideo = (video_id) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }
  
        try {
            dispatch({
                type: DELETE_VIDEO_REQUEST
            })
    
            const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/cuviewTV/delete_video/${video_id}/`,
                config
            )
  
            dispatch({
                type: DELETE_VIDEO_SUCCESS,
                payload: data,
                video_id: video_id,
            })
  
            Store.addNotification({
                content: <Notification message = {"Video has been deleted"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:DELETE_VIDEO_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }
  
    }
  }


  export const getVideoInfo = (video_id) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: GET_VIDEO_INFO_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_video_info/${video_id}/`, config
        )

        dispatch({
        type: GET_VIDEO_INFO_SUCCESS,
        payload: res.data,
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEO_INFO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const editVideo = (video_id, videoInfo, imageFile) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: EDIT_VIDEO_REQUEST
        })

        const res  = await axios.put(
            `${process.env.REACT_APP_API_URL}/cuviewTV/edit_video_info/${video_id}/`, 
            { 'data' : JSON.stringify(videoInfo), 'file':imageFile},
            config
        )

        dispatch({
        type: EDIT_VIDEO_SUCCESS,
        payload: res.data,
        video_id: video_id
        })

        Store.addNotification({
            content: <Notification message = {"Video has been Edited"}/>,
            type: 'custom',
            container: "bottom-left",
            insert: "top",
            dismiss: {
                duration: 3000,
                pauseOnHover: true
            }
            })


    } catch(error) {
        dispatch({
        type: EDIT_VIDEO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const editVideoThumbnail = (video_id, imageFile) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: EDIT_VIDEO_THUMBNAIL_REQUEST
        })

        const res  = await axios.put(
            `${process.env.REACT_APP_API_URL}/cuviewTV/edit_video_thumbnail/${video_id}/`, 
            { 'file':imageFile},
            config
        )

        dispatch({
        type: EDIT_VIDEO_THUMBNAIL_SUCCESS,
        payload: res.data,
        video_id: video_id
        })


    } catch(error) {
        dispatch({
        type: EDIT_VIDEO_THUMBNAIL_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}