import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Results from './Results';
import { getSearchResults } from '../../actions/filterActions';
import { useParams } from 'react-router-dom'

function SearchResults(props) {

    const {error, loading, searchResults} = useSelector(state => state.searchResults)

    const {keyword} = useParams()

    const [val, setVal] = useState()

    useEffect(()=>{
        setVal(keyword)
    },[keyword])

    return (
        <>
            <Results
                filterList = {props.filterList}
                setFilterList = {props.setFilterList}
                data = {searchResults}
                title = {"SEARCH RESULTS"}
                loading = {loading}
                error = {error}
                setVideo = {props.setVideo}
                filterActive = {props.filterActive}
                setFilterActive = {props.setFilterActive}
                searchFunc = {getSearchResults}
                val = {val}
            />
        </>
    );
}

export default SearchResults;