export const GET_BANNER_VIDEOS_REQUEST = 'GET_BANNER_VIDEOS_REQUEST'
export const GET_BANNER_VIDEOS_SUCCESS = 'GET_BANNER_VIDEOS_SUCCESS'
export const GET_BANNER_VIDEOS_FAIL = 'GET_BANNER_VIDEOS_FAIL'

export const BANNER_PLAYLISTS_REQUEST = 'BANNER_PLAYLISTS_REQUEST'
export const BANNER_PLAYLISTS_SUCCESS = 'BANNER_PLAYLISTS_SUCCESS'
export const BANNER_PLAYLISTS_FAIL = 'BANNER_PLAYLISTS_FAIL'

export const BANNER_THEMES_REQUEST = 'BANNER_THEMES_REQUEST'
export const BANNER_THEMES_SUCCESS = 'BANNER_THEMES_SUCCESS'
export const BANNER_THEMES_FAIL = 'BANNER_THEMES_FAIL'

export const ADD_BANNER_VIDEO_REQUEST = 'ADD_BANNER_VIDEO_REQUEST'
export const ADD_BANNER_VIDEO_SUCCESS = 'ADD_BANNER_VIDEO_SUCCESS'
export const ADD_BANNER_VIDEO_FAIL = 'ADD_BANNER_VIDEO_FAIL'

export const DELETE_BANNER_VIDEO_REQUEST = 'DELETE_BANNER_VIDEO_REQUEST'
export const DELETE_BANNER_VIDEO_SUCCESS = 'DELETE_BANNER_VIDEO_SUCCESS'
export const DELETE_BANNER_VIDEO_FAIL = 'DELETE_BANNER_VIDEO_FAIL'

export const EDIT_BANNER_VIDEO_REQUEST = 'EDIT_BANNER_VIDEO_REQUEST'
export const EDIT_BANNER_VIDEO_SUCCESS = 'EDIT_BANNER_VIDEO_SUCCESS'
export const EDIT_BANNER_VIDEO_FAIL = 'EDIT_BANNER_VIDEO_FAIL'

export const ARRANGE_BANNER_VIDEOS_REQUEST = 'ARRANGE_BANNER_VIDEOS_REQUEST'
export const ARRANGE_BANNER_VIDEOS_SUCCESS = 'ARRANGE_BANNER_VIDEOS_SUCCESS'
export const ARRANGE_BANNER_VIDEOS_FAIL = 'ARRANGE_BANNER_VIDEOS_FAIL'