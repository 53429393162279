import React, { useEffect, useRef, useState } from 'react'
import GroupsComponent from './PlaylistGroups/GroupsComponent'
import Playlists from './playlists/Playlists'
import VideoPlayer from './videoPlayler/VideoPlayer'
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AnalyzeComponent from './analyze/AnalyzeComponent'
import { getActiveVideos } from '../actions/videoActions'
import { listPlaylistVideos } from '../actions/PlaylistActions'
import {useSearchParams} from 'react-router-dom'


export default function Home(props) {

    const playerRef = useRef()

    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams([])

    const authenticate = useSelector(state => state.user)
    const [currentTime, setCurrentTime] = useState(0)

    const navigate = useNavigate()

    const { user, userLoading} = authenticate

    useEffect(()=>{
      if(searchParams.get('v')){
        dispatch(getActiveVideos(searchParams.get('v')))
      }
    },[searchParams.get('v')])

    const handleReWind = () => {
      setCurrentTime(Math.round(playerRef.current.getCurrentTime()))
    }

    const slideClickHandler = (data) => {
      localStorage.setItem('playlist_id', data.id)
      dispatch(listPlaylistVideos(data.id))
      if(data.video_id){
        dispatch(getActiveVideos(data.video_id))
        setSearchParams({ ...Object.fromEntries([...searchParams]), p:data.id, v:data.video_id})
        localStorage.setItem('video_id', data.video_id)
      } else {
        setSearchParams({ ...Object.fromEntries([...searchParams]), p:data.id}) 
      }
    }


    if(userLoading){
      return (<div>Loading...</div>)
    } else {
      if(user){
      return (
        <> 
          <GroupsComponent
            sCWHeight={12}
            setVideo = {props.setVideo}
            slideClickHandler={slideClickHandler}
            searchParams = {searchParams}
            setSearchParams = {setSearchParams}/>
          {
            props.video &&
            <VideoPlayer
              playing = {props.playing}
              video = {props.video}
              setVideo = {props.setVideo}
              playerRef = {playerRef}
              searchParams = {searchParams}
              setSearchParams = {setSearchParams}
              handleRewind={handleReWind}
            />
          }

              <AnalyzeComponent
                currentTime = {currentTime}
                video = {props.video}
              />
              {
              <Playlists
                slideClickHandler = {slideClickHandler}
                searchParams = {searchParams}
                setSearchParams = {setSearchParams}
                myPlaylistsRef = {props.myPlaylistsRef}
                />
              }
        </>
        ) } else {
          searchParams.get('v') && localStorage.setItem('video_id', searchParams.get('v')) 
          searchParams.get('p') && localStorage.setItem('playlist_id', searchParams.get('p')) 
          searchParams.get('g') && localStorage.setItem('group_id', searchParams.get('g')) 
          navigate('/login')
        }
  }
}


