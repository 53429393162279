import axios from 'axios'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Notification from '../app-main-content/playlistAdds/Notification'

import {
    
    GET_PLAYLISTS_FOR_ADMIN_REQUEST,
    GET_PLAYLISTS_FOR_ADMIN_SUCCESS,
    GET_PLAYLISTS_FOR_ADMIN_FAIL,

    PLAYLISTS_SEARCH_RESULTS_REQUEST,
    PLAYLISTS_SEARCH_RESULTS_SUCCESS,
    PLAYLISTS_SEARCH_RESULTS_FAIL,
  
    ADD_PLAYLIST_FOR_ADMIN_REQUEST,
    ADD_PLAYLIST_FOR_ADMIN_SUCCESS,
    ADD_PLAYLIST_FOR_ADMIN_FAIL,

    DELETE_PLAYLIST_FOR_ADMIN_REQUEST,
    DELETE_PLAYLIST_FOR_ADMIN_SUCCESS,
    DELETE_PLAYLIST_FOR_ADMIN_FAIL,

    PLAYLISTS_LENGTH_REQUEST,
    PLAYLISTS_LENGTH_SUCCESS,
    PLAYLISTS_LENGTH_FAIL,

    PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST,
    PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS,
    PLAYLISTS_LENGTH_FOR_SEARCH_FAIL,

    PLAYLISTS_SEARCH_SUGGESTIONS_REQUEST,
    PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS,
    PLAYLISTS_SEARCH_SUGGESTIONS_FAIL,

    PLAYLIST_INFO_REQUEST,
    PLAYLIST_INFO_SUCCESS,
    PLAYLIST_INFO_FAIL,

    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,

    EDIT_PLAYLIST_FOR_ADMIN_REQUEST,
    EDIT_PLAYLIST_FOR_ADMIN_SUCCESS,
    EDIT_PLAYLIST_FOR_ADMIN_FAIL,

    ADD_PLAYLIST_TO_GROUP_REQUEST,
    ADD_PLAYLIST_TO_GROUP_SUCCESS,
    ADD_PLAYLIST_TO_GROUP_FAIL,
  
    DELETE_PLAYLIST_FROM_GROUP_REQUEST,
    DELETE_PLAYLIST_FROM_GROUP_SUCCESS,
    DELETE_PLAYLIST_FROM_GROUP_FAIL,
  
    EDIT_PLAYLIST_THUMBNAIL_REQUEST,
    EDIT_PLAYLIST_THUMBNAIL_SUCCESS,
    EDIT_PLAYLIST_THUMBNAIL_FAIL,

} from '../constants/AdminPlaylistConstants'



export const getPlaylistsForAdmin = (page, index) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: GET_PLAYLISTS_FOR_ADMIN_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlists_for_admin/${page}/`, config
        )
  
      dispatch({
        type: GET_PLAYLISTS_FOR_ADMIN_SUCCESS,
        payload: data,
        page: page, 
        index: index
      })
  
    } catch(error) {
      dispatch({
        type: GET_PLAYLISTS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

  export const addPlaylistForAdmin = (title, status, image) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'multipart/form-data',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: ADD_PLAYLIST_FOR_ADMIN_REQUEST
      })
  
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/cuviewTV/add_playlist_for_admin/`, {'title': title, 'image':image, 'status':status}, config
        )
  
      dispatch({
        type: ADD_PLAYLIST_FOR_ADMIN_SUCCESS,
        payload: res.data
      })
  
      Store.addNotification({
        content: <Notification message = {"Playlist Has Been Added"}/>,
        type: 'custom',
        container: "bottom-left",
        insert: "top",
        dismiss: {
            duration: 3000,
            pauseOnHover: true
        }
        })

    } catch(error) {
      dispatch({
        type: ADD_PLAYLIST_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  export const editPlaylistForAdmin = (playlist_id, title, status, positions) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: EDIT_PLAYLIST_FOR_ADMIN_REQUEST
      })
  
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/cuviewTV/edit_playlist_for_admin/${playlist_id}/`, {'title': title, 'status':status, 'positions': positions}, config
        )
  
      dispatch({
        type: EDIT_PLAYLIST_FOR_ADMIN_SUCCESS,
        payload: res.data
      })
  
      Store.addNotification({
        content: <Notification message = {"Playlist Has Been Edited"}/>,
        type: 'custom',
        container: "bottom-left",
        insert: "top",
        dismiss: {
            duration: 3000,
            pauseOnHover: true
        }
        })

    } catch(error) {
      dispatch({
        type: EDIT_PLAYLIST_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

  export const deletePlaylistForAdmin = (playlist_id) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: DELETE_PLAYLIST_FOR_ADMIN_REQUEST
      })
  
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_for_admin/${playlist_id}/`, config
        )
  
      dispatch({
        type: DELETE_PLAYLIST_FOR_ADMIN_SUCCESS,
        payload: res.data,
        playlist_id: playlist_id
      })

      Store.addNotification({
        content: <Notification message = {"Playlist Has Been Deleted"}/>,
        type: 'custom',
        container: "bottom-left",
        insert: "top",
        dismiss: {
            duration: 3000,
            pauseOnHover: true
        }
        })
  
    } catch(error) {
      dispatch({
        type: DELETE_PLAYLIST_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  export const getPlaylistsSearchResults = (keyword, page, index, loadIndex) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: PLAYLISTS_SEARCH_RESULTS_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlists_search_results/${keyword}/${page}/`, config
        )
  
      dispatch({
        type: PLAYLISTS_SEARCH_RESULTS_SUCCESS,
        payload: data,
        page: page, 
        index: index,
        loadIndex: loadIndex
      })
  
    } catch(error) {
      dispatch({
        type: PLAYLISTS_SEARCH_RESULTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

  export const getPlaylistsLength = () => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }

    try {
        dispatch({
        type: PLAYLISTS_LENGTH_REQUEST
        })

        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlists_length/`, config
        )

        dispatch({
          type: PLAYLISTS_LENGTH_SUCCESS,
          payload: res.data.playlistLength,
        })

    } catch(error) {
        dispatch({
        type: PLAYLISTS_LENGTH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getplaylistsLengthForSearch = (keyword) => async (dispatch, getState) => {

  const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

  try {
      dispatch({
      type: PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST
      })

      const res  = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlists_length_for_search/${keyword}/`, config
      )

      dispatch({
      type: PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS, 
      payload: res.data.playlistLength,
      })

  } catch(error) {
      dispatch({
      type: PLAYLISTS_LENGTH_FOR_SEARCH_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
      })
  }
}


export const getPlaylistSearchSuggestions = (keyword) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }

  try {

      const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/cuviewTV/playlist_search_suggestions/${keyword}/`, {}, config
      )

      dispatch({
      type: PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS,
      payload: res.data
      })

  } catch(error) {
      dispatch({
      type: PLAYLISTS_SEARCH_SUGGESTIONS_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
      })
  }
}

export const getPlaylistInfo = (playlist_id) => async (dispatch, getState) => {

  const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

  try {
      dispatch({
      type: PLAYLIST_INFO_REQUEST
      })

      const res  = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlist_info/${playlist_id}/`, config
      )

      dispatch({
        type: PLAYLIST_INFO_SUCCESS,
        payload: res.data,
      })

  } catch(error) {
      dispatch({
      type: PLAYLIST_INFO_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
      })
  }
}


export const addPlaylistToGroup = (group, playlist_id) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }

  try {
    dispatch({
      type: ADD_PLAYLIST_TO_GROUP_REQUEST
    })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuviewTV/add_playlist_to_group/${group.id}/${playlist_id}/`, config
      )

    dispatch({
      type: ADD_PLAYLIST_TO_GROUP_SUCCESS,
      payload: data,
      playlist_id: playlist_id,
      group: group
    })

    Store.addNotification({
      content: <Notification message = {"Playlist Has Been Added To Group"}/>,
      type: 'custom',
      container: "bottom-left",
      insert: "top",
      dismiss: {
          duration: 3000,
          pauseOnHover: true
      }
      })

  } catch(error) {
    dispatch({
      type: ADD_PLAYLIST_TO_GROUP_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}


export const deletePlaylistFromGroup = (group_id, playlist_id) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }

  try {
    dispatch({
      type: DELETE_PLAYLIST_FROM_GROUP_REQUEST
    })

      const res  = await axios.delete(
        `${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_from_group/${group_id}/${playlist_id}/`, config
      )

    dispatch({
      type: DELETE_PLAYLIST_FROM_GROUP_SUCCESS,
      payload: res.data,
      playlist_id: playlist_id,
      group_id: group_id
    })

    Store.addNotification({
      content: <Notification message = {"Playlist Has Been Deleted From Group"}/>,
      type: 'custom',
      container: "bottom-left",
      insert: "top",
      dismiss: {
          duration: 3000,
          pauseOnHover: true
      } 
      })

  } catch(error) {
    dispatch({
      type: DELETE_PLAYLIST_FROM_GROUP_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}


export const deleteVideoFromPlaylistForAdmin = (video_id, playlist_id, video_position) => async (dispatch, getState) => {
  if (localStorage.getItem('access')){
      const config = {
          headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
            }
      }

      try {
          dispatch({
              type: DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST
          })

          const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_video_for_admin/${video_id}/${playlist_id}/`,
              config
          )

          dispatch({
              type: DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
              payload: data,
              video_id: video_id,
              video_position: video_position
          })

          Store.addNotification({
              content: <Notification message = {"Video has been deleted from playlist"}/>,
              type: 'custom',
              container: "bottom-left",
              insert: "top",
              dismiss: {
                  duration: 3000,
                  pauseOnHover: true
              }
              })
  
      } catch(error) {
          dispatch({
          type:DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,
          payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
          })
      }

  }
}


export const editPlaylistThumbnail = (playlist_id, image) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'multipart/form-data',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }

  try {
    dispatch({
      type: EDIT_PLAYLIST_THUMBNAIL_REQUEST
    })

      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/cuviewTV/edit_playlist_thumbnail/${playlist_id}/`, { 'image':image }, config
      )

    dispatch({
      type: EDIT_PLAYLIST_THUMBNAIL_SUCCESS,
      payload: res.data
    })


  } catch(error) {
    dispatch({
      type: EDIT_PLAYLIST_THUMBNAIL_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}