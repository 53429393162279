import React from 'react';
import SelectIcon from '../adminIcons/Select.svg'
import PlaylistComponent from './PlaylistComponent';

const PlaylistList = (props) => {
    return (
        <>
        {
            props.index ?
            props.index === 3 ? <div className = "fs-8 text-secondary px-3 pt-4 fw-bold">SEARCH RESULTS</div> : "" : ""
        }
        <div className='w-100 mt-4 bg-success rounded mb-5'>
            <div className='w-100 bg-secondary rounded-top d-flex align-items-center p-3'>
                <div className = 'text-info fw-bold ps-3' style = {{width:'12%'}}>Cover image</div>
                <div className = 'text-info fw-bold px-2' style = {{width:'50%'}}>Title</div>
                <div className = 'text-info fw-bold px-2' style = {{width:'29%'}}>Updated</div>
                <div className = 'text-info fw-bold px-2' style = {{width:'8%'}}>Action</div>
            </div>
            {
                props.playlists && props.playlists.length ? 
                <div>
                    {
                        props.playlists.map((playlist, index) => (
                            <div key ={index} >
                                <PlaylistComponent
                                    playlist = {playlist}
                                />
                            </div>
                        ))
                    }
                </div> 
                : ''
            }
        </div>
    </>
    );
};

export default PlaylistList;