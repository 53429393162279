import axios from 'axios'
import {

    WATERMARK_REQUEST,
    WATERMARK_SUCCESS,
    WATERMARK_FAIL,

    FOOTPRINT_REQUEST,
    FOOTPRINT_SUCCESS,
    FOOTPRINT_FAIL,

    ANALYZE_INFO_REQUEST,
    ANALYZE_INFO_SUCCESS,
    ANALYZE_INFO_FAIL,

} from '../constants/AnalyzeConstants'


let controller = new AbortController();   

export const getAnalyzeInfo = (video_id, time) => async (dispatch, getState) => {
    
    try {
        dispatch({
            type: ANALYZE_INFO_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_analyze_info/${video_id}/${time}/`, {
                signal: controller.signal
              }
        )

        dispatch({
            type: ANALYZE_INFO_SUCCESS,
            payload: data
        })

        dispatch(getWatermark(data.watermark))
        dispatch(getFootprint(data.footprint))

    } catch(error) {
        dispatch({
        type: ANALYZE_INFO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getWatermark = (watermark) => async (dispatch, getState) => {

    const session_id = localStorage.getItem("session")

    try {
        dispatch({
            type: WATERMARK_REQUEST
        })

        const body = {
                "wm_numbers": [watermark,156,300,400],
                "get_as_link": true,
                "session_id": session_id
            }

        const data = await axios.post(
            "https://extension.cutrack.net/get_info_by_wm_nums/", body,
                {
                    signal: controller.signal
              }
        )

        dispatch({
            type: WATERMARK_SUCCESS,
            watermark_info: data.data.data.result,
        })

    } catch(error) {
        dispatch({
        type: WATERMARK_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getFootprint = (footprint) => async (dispatch, getState) => {

    const session_id = localStorage.getItem("session")

    try {
        dispatch({
            type: FOOTPRINT_REQUEST
        })

        const body = {
                "fp_numbers": footprint,
                "get_as_link": true,
                "session_id": session_id
            }

        const data = await axios.post(
            "https://extension.cutrack.net/get_info_by_fp_nums/", body,
            {
                signal: controller.signal
              }
        )

        dispatch({
            type: FOOTPRINT_SUCCESS,
            footprint_info: data.data.data.result,
        })

    } catch(error) {
        dispatch({
        type: FOOTPRINT_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const stopAnalyze = () => {
    controller.abort();
    controller = new AbortController()
}