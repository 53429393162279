import React from 'react';
import { useSelector } from 'react-redux';

const ChooseTheme = (props) => {

    const { bannerThemes } = useSelector(state=>state.bannerThemes)

    const clickHandler = (theme) => {
        props.setThemeId(theme.id)
        props.setIndex(4)
    }

    return (
        <div>
            <div className = 'px-2 text-secondary fs-8 pb-3 fw-bold'>CHOOSE THEME</div>
            {
                bannerThemes && bannerThemes.length ? 
                bannerThemes.map((theme, index)=>(
                    <div 
                        className = 'd-flex border border-primary p-2 bg-secondary my-2 rounded justify-content-center align-items-center' 
                        key = {index}
                        role = 'button'
                        onClick={()=>clickHandler(theme)}
                        >
                        <div className = 'text-white ps-4'>{theme.title}</div>
                    </div> 
                )) : ""
            }
        </div>
    );
};

export default ChooseTheme;