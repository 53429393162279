import React from 'react';
import deleteIcon from '../adminIcons/CrossIcon.svg'


const AddVideoFilterComponent = (props) => {

    return (
        <>
            <div style={{width:props.width}}>
                <div className = 'd-flex w-100 bg-warning rounded py-2 flex-wrap'>
                {
                    props.chosenData && props.chosenData.length ? props.chosenData.map((item, index) => (
                        <div className = "ps-2 d-flex align-items-center" key = {index}>
                            <div className = "text-white pe-1 text-nowrap">{item.title}</div>
                            <img src = {deleteIcon} alt = "" role = 'button'
                            onClick={()=>props.setChosenData(props.chosenData.filter(data=>parseInt(data.id) != parseInt(item.id)))}/>
                        </div>
                    )) : ""
                }
                    <input
                        type="text"
                        className="fs-5 border-0 bg-warning text-white search-input flex-grow-1 ps-3"
                        placeholder={props.chosenData.length ? "": 'Select...'}
                        onChange={(e)=>props.setValue(e.target.value)}
                        onClick={()=>{props.setActive(true)}}
                        value = {props.value}/>
                </div>
                {
                    props.isActive ?
                    <div className = "w-100 position-relative d-flex justify-content-end" 
                        style={{userSelect:"none"}} ref = {props.componentRef}>
                        <div className = "w-100 position-absolute mt-3 text-dark p-2 bg-white shadow rounded" 
                            style = {{zIndex:1000}}>
                            <div className='addToplaylist-list-wrapper'
                                onScroll = {props.handleScroll}
                                style={{maxHeight:"20rem"}}
                            >
                                {
                                    props.loading ? <>Loading...</> :
                                    props.data && props.data.length ? 
                                    props.data.filter(item=>!props.chosenData.map(i=>parseInt(i.id)).includes(parseInt(item.id))).map((item) => (
                                        <div
                                            className = "px-1 py-2 text-dark addSearchDropdown-item"
                                            role = "button"
                                            style={{transition:"all 0.2s", wordBreak:"normal", lineHeight:"1.125rem"}}
                                            key = {item.id}
                                            onClick = {()=>props.chooseHandler(item)}
                                        >{item.title.toLowerCase()}</div>
                                    )) : <div className='text-dark'>No result</div>
                                }
                            </div>
                        </div>  
                    </div> : "" 
                }
            </div>
        </>
    );
};

export default AddVideoFilterComponent;