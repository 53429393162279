import axios from 'axios'

import {
    GET_GENRES_FOR_ADMIN_REQUEST,
    GET_GENRES_FOR_ADMIN_SUCCESS,
    GET_GENRES_FOR_ADMIN_FAIL,

    GET_MOODS_FOR_ADMIN_REQUEST,
    GET_MOODS_FOR_ADMIN_SUCCESS,
    GET_MOODS_FOR_ADMIN_FAIL,

    GET_VIDEOGENRES_FOR_ADMIN_REQUEST,
    GET_VIDEOGENRES_FOR_ADMIN_SUCCESS,
    GET_VIDEOGENRES_FOR_ADMIN_FAIL,

    GET_TAGS_FOR_ADMIN_REQUEST,
    GET_TAGS_FOR_ADMIN_SUCCESS,
    GET_TAGS_FOR_ADMIN_FAIL,

    GET_ALBUMS_FOR_ADMIN_REQUEST,
    GET_ALBUMS_FOR_ADMIN_SUCCESS,
    GET_ALBUMS_FOR_ADMIN_FAIL,

    GET_TRACKS_FOR_ADMIN_REQUEST,
    GET_TRACKS_FOR_ADMIN_SUCCESS,
    GET_TRACKS_FOR_ADMIN_FAIL,

    ADD_VIDEOGENRE_REQUEST,
    ADD_VIDEOGENRE_SUCCESS,
    ADD_VIDEOGENRE_FAIL,

    EDIT_VIDEOGENRE_REQUEST,
    EDIT_VIDEOGENRE_SUCCESS,
    EDIT_VIDEOGENRE_FAIL,

    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAIL,

    EDIT_TAG_REQUEST,
    EDIT_TAG_SUCCESS,
    EDIT_TAG_FAIL,

    GET_ALBUMS_LENGTH_REQUEST,
    GET_ALBUMS_LENGTH_SUCCESS,
    GET_ALBUMS_LENGTH_FAIL,

    GET_TRACKS_LENGTH_REQUEST,
    GET_TRACKS_LENGTH_SUCCESS,
    GET_TRACKS_LENGTH_FAIL,

} from '../constants/AdminFilterConstants'


export const getGenresForAdmin = () => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_GENRES_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_genres/`
        )

        dispatch({
        type: GET_GENRES_FOR_ADMIN_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: GET_GENRES_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getMoodsForAdmin = () => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_MOODS_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_moods/`
        )

        dispatch({
        type: GET_MOODS_FOR_ADMIN_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: GET_MOODS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}



export const getVideogenresForAdmin = (keyword, page, index) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_VIDEOGENRES_FOR_ADMIN_REQUEST
        })

        let a 

        if (!keyword.length){
            a = ' '
        } else {
            a = keyword
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videogenres_for_admin_filter/${a}/${page}/`
        )

        dispatch({
        type: GET_VIDEOGENRES_FOR_ADMIN_SUCCESS,
        payload: data, 
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEOGENRES_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getTagsForAdmin = (keyword, page, index) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_TAGS_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_tags_for_admin_filter/${keyword}/${page}/`
        )

        dispatch({
        type: GET_TAGS_FOR_ADMIN_SUCCESS,
        payload: data, 
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_TAGS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getAlbumsForAdmin = (keyword, page, index) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_ALBUMS_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_albums_for_admin_filter/${keyword}/${page}/`
        )

        dispatch({
        type: GET_ALBUMS_FOR_ADMIN_SUCCESS,
        payload: data, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: GET_ALBUMS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getTracksForAdmin = (album_id, page) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_TRACKS_FOR_ADMIN_REQUEST
        })

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_tracks_for_admin_filter/${album_id}/${page}/`
        )

        dispatch({
        type: GET_TRACKS_FOR_ADMIN_SUCCESS,
        payload: data
        })

    } catch(error) {
        dispatch({
        type: GET_TRACKS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const addVideogenre = (videogenre_title) => async (dispatch, getState) => {

    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type:ADD_VIDEOGENRE_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_videogenre/`,
                { 'title': videogenre_title },
                config
            )
            
            dispatch({
                type:ADD_VIDEOGENRE_SUCCESS,
                payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type:ADD_VIDEOGENRE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}

export const addTag = (tag_title) => async (dispatch, getState) => {
    
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type:ADD_TAG_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_tag/`,
                { 'title': tag_title },
                config
            )
            
            dispatch({
                type:ADD_TAG_SUCCESS,
                payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type:ADD_TAG_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}

export const edit_videogenre = (videogenre_id, videogenre_title) => async (dispatch, getState) => {
    
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type:EDIT_VIDEOGENRE_REQUEST
            })
    
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/cuviewTV/edit_videogenre/${videogenre_id}/`,
                { 'title': videogenre_title },
                config
            )
            
            dispatch({
                type:EDIT_VIDEOGENRE_SUCCESS,
                payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type:EDIT_VIDEOGENRE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}


export const edit_tag = (tag_id, tag_title) => async (dispatch, getState) => {
    
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type:EDIT_TAG_REQUEST
            })
    
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/cuviewTV/edit_tag/${tag_id}/`,
                { 'title': tag_title },
                config
            )
            
            dispatch({
                type:EDIT_TAG_SUCCESS,
                payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type:EDIT_TAG_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}


export const getAlbumsLength = (keyword) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }
  
    try {
        dispatch({
        type: GET_ALBUMS_LENGTH_REQUEST
        })
  
        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_albums_length/${keyword}/`, config
        )
  
        dispatch({
        type: GET_ALBUMS_LENGTH_SUCCESS, 
        payload: res.data.albumsLength,
        })
  
    } catch(error) {
        dispatch({
        type: GET_ALBUMS_LENGTH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
  }


  export const getTracksLength = (album_id) => async (dispatch, getState) => {

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        }
  
    try {
        dispatch({
        type: GET_TRACKS_LENGTH_REQUEST
        })
  
        const res  = await axios.get(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_tracks_length/${album_id}/`, config
        )
  
        dispatch({
        type: GET_TRACKS_LENGTH_SUCCESS, 
        payload: res.data.tracksLength,
        })
  
    } catch(error) {
        dispatch({
        type: GET_TRACKS_LENGTH_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
  }