import React, {useEffect} from 'react';
import './App.css';
import Content from './Content'
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import icon from './icon-cugatetv.svg'


function App() {

  useEffect(() => {
      const favicon = document.getElementById('favicon');
      favicon.setAttribute('href', icon);
  }, []);


  return (
    <Router>
      <div className="position-relative d-flex flex-column min-vh-100">
        <ReactNotifications />
          <Content/>
      </div>
    </Router>
  )
}

export default App