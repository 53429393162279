import { Outlet } from "react-router-dom";
import AdminMenu from "./AdminMenu";
import { useSelector } from "react-redux";

const AdminLayout = (props) => {

  const { user, userLoading } = useSelector(state=>state.user)

    return (
      <div className="d-flex position-relative">
        {       
          <div className = "h-100 w-100 bg-warning" style = {{minHeight:"100vh"}}>
            {
              userLoading ? 
              <div className='text-info'>Loading...</div> : !user || !user.is_staff ?
              <div className='text-info'>You don't have permission for this Action</div> :
              <>
                <AdminMenu 
                  changes = {props.changes}
                  setChanges = {props.setChanges}
                />
                <Outlet />
              </>
            }
          </div>
        }
      </div>
    );
  };
  
  export default AdminLayout;