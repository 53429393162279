export const GET_GENRES_FOR_ADMIN_REQUEST = 'GET_GENRES_FOR_ADMIN_REQUEST'
export const GET_GENRES_FOR_ADMIN_SUCCESS = 'GET_GENRES_FOR_ADMIN_SUCCESS'
export const GET_GENRES_FOR_ADMIN_FAIL = 'GET_GENRES_FOR_ADMIN_FAIL'

export const GET_MOODS_FOR_ADMIN_REQUEST = 'GET_MOODS_FOR_ADMIN_REQUEST'
export const GET_MOODS_FOR_ADMIN_SUCCESS = 'GET_MOODS_FOR_ADMIN_SUCCESS'
export const GET_MOODS_FOR_ADMIN_FAIL = 'GET_MOODS_FOR_ADMIN_FAIL'

export const GET_VIDEOGENRES_FOR_ADMIN_REQUEST = 'GET_VIDEOGENRES_FOR_ADMIN_REQUEST'
export const GET_VIDEOGENRES_FOR_ADMIN_SUCCESS = 'GET_VIDEOGENRES_FOR_ADMIN_SUCCESS'
export const GET_VIDEOGENRES_FOR_ADMIN_FAIL = 'GET_VIDEOGENRES_FOR_ADMIN_FAIL'

export const GET_TAGS_FOR_ADMIN_REQUEST = 'GET_TAGS_FOR_ADMIN_REQUEST'
export const GET_TAGS_FOR_ADMIN_SUCCESS = 'GET_TAGS_FOR_ADMIN_SUCCESS'
export const GET_TAGS_FOR_ADMIN_FAIL = 'GET_TAGS_FOR_ADMIN_FAIL'

export const GET_ALBUMS_FOR_ADMIN_REQUEST = 'GET_ALBUMS_FOR_ADMIN_REQUEST'
export const GET_ALBUMS_FOR_ADMIN_SUCCESS = 'GET_ALBUMS_FOR_ADMIN_SUCCESS'
export const GET_ALBUMS_FOR_ADMIN_FAIL = 'GET_ALBUMS_FOR_ADMIN_FAIL'

export const GET_TRACKS_FOR_ADMIN_REQUEST = 'GET_TRACKS_FOR_ADMIN_REQUEST'
export const GET_TRACKS_FOR_ADMIN_SUCCESS = 'GET_TRACKS_FOR_ADMIN_SUCCESS'
export const GET_TRACKS_FOR_ADMIN_FAIL = 'GET_TRACKS_FOR_ADMIN_FAIL'

export const ADD_VIDEOGENRE_REQUEST = 'ADD_VIDEOGENRE_REQUEST'
export const ADD_VIDEOGENRE_SUCCESS = 'ADD_VIDEOGENRE_SUCCESS'
export const ADD_VIDEOGENRE_FAIL = 'ADD_VIDEOGENRE_FAIL'

export const EDIT_VIDEOGENRE_REQUEST = 'EDIT_VIDEOGENRE_REQUEST'
export const EDIT_VIDEOGENRE_SUCCESS = 'EDIT_VIDEOGENRE_SUCCESS'
export const EDIT_VIDEOGENRE_FAIL = 'EDIT_VIDEOGENRE_FAIL'

export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_FAIL = 'ADD_TAG_FAIL'

export const EDIT_TAG_REQUEST = 'EDIT_TAG_REQUEST'
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS'
export const EDIT_TAG_FAIL = 'EDIT_TAG_FAIL'

export const GET_ALBUMS_LENGTH_REQUEST = 'GET_ALBUMS_LENGTH_REQUEST'
export const GET_ALBUMS_LENGTH_SUCCESS = 'GET_ALBUMS_LENGTH_SUCCESS'
export const GET_ALBUMS_LENGTH_FAIL = 'GET_ALBUMS_LENGTH_FAIL'

export const GET_TRACKS_LENGTH_REQUEST = 'GET_TRACKS_LENGTH_REQUEST'
export const GET_TRACKS_LENGTH_SUCCESS = 'GET_TRACKS_LENGTH_SUCCESS'
export const GET_TRACKS_LENGTH_FAIL = 'GET_TRACKS_LENGTH_FAIL'