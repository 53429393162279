import React, {useState} from 'react'
import AddVideoToPlaylistList from './AddVideoToPlaylistList'
import close from '../icons/close.svg'
import { useDispatch } from 'react-redux'
import { add_full_playlist } from '../../actions/PlaylistActions'

const AddVideoToPlaylistContent = ( props ) => {

  const [playlistName, setPlaylistName] = useState("")

  const dispatch = useDispatch()

  const [addPlaylistActive, setAddPlaylistActive] = useState(false)

  const submitHandler = (e) => {
    e.preventDefault()
    if(/^[a-zA-Z0-9\s]*$/.test(playlistName) && playlistName.length>1){
      dispatch(add_full_playlist(playlistName, props.video_list))
    } else {
      alert("Please, Provide Valid Name")
    }
    setPlaylistName("")
    setAddPlaylistActive(false)
  }

  return (
      <div className='d-flex justify-content-center justify-content-lg-end'>
        {
          addPlaylistActive && 
          <div className = "me-2" style={{width:"220px"}}>
            <div className = 'w-100 bg-secondary d-flex justify-content-between py-2 px-2 rounded-top'>
              <div className='text-white px-3' style={{fontWeight: 500}}>Create Playlist</div>
              <img src={close} alt = "close" role="button" onClick={()=>{setAddPlaylistActive(false)}}/>
            </div>
            <div className='col-12 p-3 rounded-bottom' style={{background:"#F3F9FF", gap:"1rem"}}>
              <div className='w-100'>
                <input
                  id="playlist-input"
                  className='w-100 border-0 p-2 my-3 text-secondary'
                  style={{background:"#DDE9F4"}}
                  type = "text"
                  placeholder = "Name..."
                  value = {playlistName}
                  onChange = {(e) => setPlaylistName(e.target.value)}
                  required
                />
                <button className='w-100 py-1 rounded-pill border-info text-info mt-1 mb-3' 
                  style={{fontWeight:500}}
                  onClick={submitHandler}
                  >Create</button>
              </div>
            </div>
          </div>
        }
      <div className={addPlaylistActive ? 'd-none d-md-block' : ''} style={{width:"220px"}}>
        <div className = 'w-100 bg-secondary d-flex justify-content-between py-2 px-2 rounded-top'>
          <div className='text-white px-3' style={{fontWeight: 500}}>Add to playlist</div>
          <img src={close} alt = "close" role="button" onClick={()=>props.setIsActive(false)}/>
        </div>
        <div className='col-12 p-3 rounded-bottom' style={{background:"#F3F9FF"}}>
          <div className = "w-100 addToplaylist-list-wrapper">
            <AddVideoToPlaylistList
              video_list = {props.video_list}
              index = {props.index}
              video = {props.video}
            />
          </div>
          <div className='w-100 px-2'>
            <button className='w-100 bg-info py-1 rounded-pill border-0 text-white mt-1' 
            onClick={()=>setAddPlaylistActive(true)}
            style={{fontWeight:500}}>Create New</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default AddVideoToPlaylistContent;

