import React, {useEffect, useState, useRef} from 'react'
import { getGenres } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const Genres = (props) => {

    const dispatch = useDispatch()

    const genreList = useSelector(state => state.genres)
  
    const {error, loading, genres} = genreList

    const [genreActive, setGenreActive] = useState(false)

    const [genreValue, setGenreValue] = useState()

    const searchGenreRef = useRef()

    useEffect(()=>{
 
        dispatch(getGenres(props.filterList))
    
      },[props.filterList])


    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchGenreRef == null || searchGenreRef.current == null || !searchGenreRef.current.contains(event.target)){
                setGenreActive(false)
            }
        })
    }) 


    const chooseHandler = (genre) => {
        if (props.filterList.filter(g => g[0] === genre.id && g[2] === 1 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [genre.id, genre.title, 1]]})
        }
        setGenreActive(false)
    }

    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {genres}
                    setValue = {setGenreValue}
                    setActive = {setGenreActive}
                    value = {genreValue}
                    isActive = {genreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchGenreRef}
                    title = "Genres"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {genres}
                    setValue = {setGenreValue}
                    setActive = {setGenreActive}
                    value = {genreValue}
                    isActive = {genreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchGenreRef}
                    title = "Genres"
                    loading = {loading}
            />
            }
        </>
    );
};

export default Genres;