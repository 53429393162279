import React, {useEffect, useState} from 'react';
import AnalyzeHeader from './AnalyzeHeader';
import AnalyzeBody from './AnalyzeBody';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyzeInfo } from '../../actions/AnalyzeActions'

const AnalyzeComponent = ({currentTime, video}) => {

    let time = 0

    const dispatch = useDispatch()

    const [activeData, setActiveData] = useState({})
    const [resultLink, setResultLink] = useState("")
    const [activeHeader, setActiveHeader] = useState(0)

    const { watermark_info, footprint_info } = useSelector(state=>state.analyze_info)

 
    useEffect(()=>{
        if(video && currentTime!=0){
            if (video.duration-30<currentTime){
                time = Math.floor((video.duration-30)/5)*5
            } else {
                time = Math.floor(currentTime/5)*5
            }
            dispatch(getAnalyzeInfo(video.id, time))
        }
    },[currentTime])

    useEffect(()=>{
        if (watermark_info && watermark_info.wm_track){
            setActiveData(watermark_info.wm_track); 
            setResultLink(watermark_info.result_link)
            setActiveHeader(1)
        } else if (footprint_info && footprint_info.fp_track){
            setActiveData(footprint_info.fp_track); 
            setResultLink(footprint_info.result_link)
            setActiveHeader(2)
        }

    },[watermark_info, footprint_info])

    useEffect(()=>{
        setActiveData()
    },[video])

    return (
        <>
            {
                activeData &&
                <>
                    <AnalyzeHeader
                        setActiveData = {setActiveData}
                        setResultLink = {setResultLink}
                        activeHeader={activeHeader}
                        setActiveHeader={setActiveHeader}
                    />
                    <AnalyzeBody
                        activeData = {activeData}
                        resultLink = {resultLink}
                    />
                </>
            }
        </>
    );
}

export default AnalyzeComponent;