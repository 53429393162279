import React, {useEffect, useState} from 'react';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import { addBannerVideo } from '../../actions/BannerActions';
import { useDispatch } from 'react-redux';

const BannerTitle = (props) => {

    const [videoOff, setVideoOff] = useState(0)
    const [title, setTitle] = useState('')

    const dispatch = useDispatch()

    useEffect(()=>{
        let t = props.playlist.title + ' - ' + props.video.title
        setTitle(t)
    },[])

    const saveHandler = () => {
        if (!title.length){
            alert('Please, Provide Title')
        } else {
            let bannerVideo = {}
            bannerVideo.video_id = props.video.id
            bannerVideo.playlist_id = props.playlist.id
            bannerVideo.group_id = props.themeId
            bannerVideo.status = videoOff
            bannerVideo.title = title
            dispatch(addBannerVideo(bannerVideo))
            props.setIsActive(false)
        }
    }

    return (
        <div>
            <div className = 'px-2 text-secondary fs-8 pb-3 fw-bold'>SET BANNER TITLE</div>
            <div className = 'py-2 my-3 border border-info rounded'>
                <input
                    type="text"
                    className="col-11 border-0 bg-success text-white search-input ps-3"
                    placeholder="Write or Select"
                    onChange={(e)=>{setTitle(e.target.value)}}
                    value={title}
                />
            </div>
            <div className = 'd-flex justify-content-between align-items-center pt-4'>
                <div className = "py-3 d-flex" role = "button">
                    {
                        videoOff ? 
                        <div className = 'd-flex ps-3'>
                            <img src = {SwitchOn} alt = "" onClick={()=>setVideoOff(0)}/>
                            <div className = 'ps-4 text-info'>Access On</div>
                        </div> :
                        <div className = 'd-flex ps-3'>
                            <img src = {SwitchOff} alt = "" onClick={()=>setVideoOff(1)}/> 
                            <div className = 'ps-4 text-info'>Access Off</div>
                        </div>
                    }
                </div>
                <div className = "text-white px-5 py-2 bg-secondary rounded-pill"
                    role = 'button'
                    onClick={()=>saveHandler()}
                >Save</div> 
            </div>
        </div>
    );
};

export default BannerTitle;