import React, {useState, useEffect} from 'react';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import EditIcon from '../adminIcons/EditIcon.svg'
import EditTitle from './EditTitle';
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import { editBannerVideo } from '../../actions/BannerActions';
import { useDispatch } from 'react-redux';


const EditBannerVideo = (props) => {

    const [imageURL, setImageUrl] = useState()

    const [imageFile, setImageFile] = useState()

    const [videoOff, setVideoOff] = useState(0)
    
    const [bannerTitle, setBannerTitle] = useState()

    const [editTitleActive, setEditTitleActive] = useState(false)

    const {width, height} = useWindowDimentions()

    const dispatch = useDispatch()

    useEffect(()=>{
        if(props.videoForEdit){
            setBannerTitle(props.videoForEdit.banner_title)
            setVideoOff(props.videoForEdit.status)
            setImageUrl(`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.videoForEdit.video_id}&s=300`)
            props.editBannerVideoRef.current && props.editBannerVideoRef.current.scrollIntoView({ behavior: "smooth" })
        }
    },[props.videoForEdit])

    
    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }   
        return members
    }


    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    const handleImage = (e) => {
        setImageFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        setImageUrl(u)
    }


    const changeHandler = () => {
        if (!bannerTitle.length){
            alert('Please, Provide Title')
        } else {
            dispatch(editBannerVideo(props.videoForEdit.id, bannerTitle, videoOff, imageFile))
            props.setVideoForEdit({})
        }
    }
    

    return (
        <div>
            {
                props.videoForEdit && props.videoForEdit.video_id ? 
                <div className='col-12 d-flex flex-wrap pe-5 py-5' ref = {props.editBannerVideoRef}>
                    <div className = 'w-100 d-flex bg-success rounded p-3'>
                        <div className = 'col-3'>
                            <div className = 'col-11 bg-warning rounded p-2'>
                                {
                                    imageURL &&
                                    <img
                                        className='w-100'
                                        src = {imageURL}
                                        style={{aspectRatio:"16/9"}}
                                    />
                                }
                                <div className='w-100 d-flex justify-content-between py-2'>
                                    {
                                        imageFile && imageFile.name ? 
                                        <div className='w-75 text-info text-wrap overflow-hidden'>{imageFile.name}</div> :
                                        <div className='text-info'>Banner Image</div>
                                    }
                                    <div>
                                        <input 
                                            id = "reimage-input"
                                            type="file" 
                                            name="imageFile"
                                            accept='image/jpeg, image/png, image/jpg'
                                            onChange={handleImage}
                                            style={{display:"none"}}
                                            />
                                        <label for="reimage-input">
                                            <div 
                                                className = "text-info text-decoration-underline"
                                                role='button'
                                            >Change</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-9 d-flex flex-column justify-content-between'>
                            <div className = 'w-100 d-flex'>
                                <div className = 'col-2 d-flex justify-content-start flex-column align-items-end' >
                                    <div className = 'text-info fw-bold'>Title</div>
                                    <div className = 'text-info fw-bold'>Video Title</div>
                                    <div className = 'text-info fw-bold'>Genre</div>
                                    <div className = 'text-info fw-bold'>Artists/Composers</div>
                                    <div className = 'text-info fw-bold'>Playlist</div>
                                    <div className = 'text-info fw-bold'>Theme</div>
                                    <div className = 'text-info fw-bold'>Duration</div>
                                </div>
                                <div className = 'col-7 d-flex justify-content-start flex-column align-items-start ps-4' >
                                    <div className = 'w-100 d-flex align-items-center'>
                                        <div className = 'text-white fw-bold text-nowrap pe-4 overflow-hidden'
                                            title = {bannerTitle}
                                        >{bannerTitle}</div>
                                        <img 
                                            className = 'col-1' 
                                            style={{width:"25px"}}
                                             src = {EditIcon}
                                             role = 'button'
                                             onClick={()=>{setEditTitleActive(true)}}
                                             alt = ""></img>
                                    </div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                        title = {props.videoForEdit.video_title}
                                    >{props.videoForEdit.video_title}</div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                        title = {props.videoForEdit.genres}
                                    >{props.videoForEdit.genres}</div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                     title = {get_members(props.videoForEdit)}
                                    >{get_members(props.videoForEdit)}</div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                        title = {props.videoForEdit.playlist_title}
                                    >{props.videoForEdit.playlist_title}</div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                        title = {props.videoForEdit.group_title}
                                    >{props.videoForEdit.group_title}</div>
                                    <div className = 'w-100 text-white fw-bold text-nowrap overflow-hidden'
                                        title = {toTimeString(props.videoForEdit.video_duration)}
                                    >{toTimeString(props.videoForEdit.video_duration)}</div>
                                </div>
                                <div className = 'col-3 d-flex align-items-start justify-content-end pe-3 pt-3'>
                                    <div className = 'bg-secondary px-5 py-2 text-white rounded-pill'
                                        role='button'
                                        onClick={()=>changeHandler()}
                                    >Save</div>
                                </div>
                            </div>
                            <div className = "py-2 d-flex" role = "button">
                                {
                                    videoOff ? 
                                    <div className = 'd-flex ps-3'>
                                        <img src = {SwitchOn} alt = "" onClick={()=>setVideoOff(0)}/>
                                        <div className = 'ps-4 text-info'>Access On</div>
                                    </div> :
                                    <div className = 'd-flex ps-3'>
                                        <img src = {SwitchOff} alt = "" onClick={()=>setVideoOff(1)}/> 
                                        <div className = 'ps-4 text-info'>Access Off</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div> : ""
            }
            <Modal 
                trigger = {editTitleActive}
                setTrigger = {setEditTitleActive}
                top = {0}
                right = {0}
                content = {
                    <EditTitle
                        setIsActive = {setEditTitleActive}
                        width = {width}
                        height = {height}
                        setBannerTitle = {setBannerTitle}
                        bannerTitle = {bannerTitle}
                    /> 
                }
            /> 
        </div>
    );
};

export default EditBannerVideo;