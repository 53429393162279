import React, {useEffect} from 'react';
import { useSelector } from 'react-redux'
import { stopAnalyze } from '../../actions/AnalyzeActions'

const AnalyzeButton = (props) => {

    const { end_watermark, end_footprint} = useSelector(state=>state.analyze_info)

    useEffect(()=>{
      stopAnalyze()
    },[props.video])
    
    return (
        <div className = "col-3 col-lg-1 position-relative d-flex align-items-center justify-content-center pe-2 pe-lg-0" >
            <div className ={end_watermark && end_footprint ? 
                "rounded-circle ring" :
                "position-relative bg-dark rounded-circle ring animate" }
                style={ end_watermark && end_footprint ? { borderTop:"transparent"}: {borderTop:"0.3vw solid #00C8D5"}}>
            </div>
            {
            end_watermark && end_footprint ? 
            <div 
                role = "button"
                id = "analyze-button"
                onClick={()=>props.handleRewind()}
                className = "d-flex align-items-center justify-content-center fw-bold position-absolute text-center bg-info text-primary border border-primary rounded-circle"
            >ANALYZE</div> :
            <div 
                role = "button"
                id = "analyze-button"
                onClick={()=>stopAnalyze()}
                className = "d-flex align-items-center justify-content-center fw-bold position-absolute text-center bg-info text-primary border border-primary rounded-circle"
            >STOP</div> 
            }

      </div>
    );
}

export default AnalyzeButton;