import React from 'react';
import SelectIcon from '../adminIcons/Select.svg'
import VideoComponent from './VideoComponent';

const VideoList = (props) => {

    return (
        <>
            {
                props.index ?
                props.index === 2 ? <div className = "fs-8 text-secondary px-3 pt-4 fw-bold">FILTER RESULTS</div> : 
                props.index === 3 ? <div className = "fs-8 text-secondary px-3 pt-4 fw-bold">SEARCH RESULTS</div> : "" : ""
            }
            <div className='w-100 mt-4 bg-success rounded mb-5'>
                <div className='w-100 bg-secondary rounded-top d-flex align-items-center p-3'>
                    <div style = {{width:'3%'}}>
                        <img src = {SelectIcon}/>
                    </div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'10%'}}>Cover image</div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'23%'}}>Title</div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'10%'}}>Genres</div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'11%'}}>Moods</div>
                    <div className = 'text-info fw-bold text-center px-2' style = {{width:'9%'}}>Duration</div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'26%'}}>Tags</div>
                    <div className = 'text-info fw-bold px-2' style = {{width:'8%'}}>Action</div>
                </div>
                {
                    props.videos && props.videos.length ? 
                    <div>
                        {
                            props.videos.map((video, index) => (
                                <div key ={index} >
                                    <VideoComponent
                                        video = {video}
                                        setVideoList = {props.setVideoList}
                                        videoList = {props.videoList}
                                    />
                                </div>
                            ))
                        }
                    </div> 
                    : ''
                }
            </div>
        </>
    );
};

export default VideoList;