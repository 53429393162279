import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import close from '../../app-main-content/icons/close.svg'
import { getPlaylistsSearchResults} from '../../actions/AdminPlaylistsActions';
import { addVideoToPlaylistForAdmin  } from '../../actions/videoActions'



const AddSeveralVideos = (props) => {

    const dispatch = useDispatch()

    const { adminPlaylists } = useSelector(state=>state.adminPlaylists)

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    const [val, setVal] = useState("")

    const [page, setPage] = useState(1)

    const [searchValue, setSearchValue] = useState("")


    useEffect(()=>{
        if (searchValue){
            dispatch(getPlaylistsSearchResults(searchValue, page, 1, 2))
        } else {
            dispatch(getPlaylistsSearchResults(" ", page, 1, 2))
        }
    }, [page])

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (searchValue){
                dispatch(getPlaylistsSearchResults(searchValue, 1, 1, 1))
            } else {
                dispatch(getPlaylistsSearchResults(" ", 1, 1, 1))
            }
        }, 500)  
    
        return () => { clearTimeout(identifer) }
    
      },[val])

    const clickHandler = (playlist) => {
        dispatch(addVideoToPlaylistForAdmin(props.videoList, playlist))
        props.setAddActive(false)
      }

    return (
        <div style={{width:"300px"}}>
            <div className = 'w-100 bg-secondary d-flex justify-content-between py-2 px-2 rounded-top'>
                <div className='text-white px-3' style={{fontWeight: 500}}>Add to Playlist</div>
                <img src={close} alt = "close" role="button" onClick={()=>props.setIsActive(false)}/>
            </div>
            <div className = "px-2 position-relative d-flex justify-content-end bg-white pt-2 align-items-center" 
                style={{userSelect:"none", height:"2.5rem"}} 
                >
                <div className="w-100 h-100 bg-light d-flex py-2 me-3 rounded border border-info"
                >
                    <input
                        type="text"
                        className="w-100 fs-5 border-0 bg-light text-secondary search-input px-2"
                        placeholder="Write or Select"
                        onChange={(e)=>{setSearchValue(e.target.value); setVal(e.target.value)}}
                        value={searchValue}
                    />
                </div>
            </div>
            <div className='col-12 p-3 rounded-bottom' style={{background:"#F3F9FF"}}>
                <div className = "w-100 addToplaylist-list-wrapper"
                    onScroll = {handleScroll}
                >
                    <div>
                        {
                            adminPlaylists && adminPlaylists.length ?
                            adminPlaylists.map((playlist, index)=>(
                                <div className='d-flex' key = {index}
                                    onClick={()=>clickHandler(playlist)}>
                                    <div key = {index}
                                        role = "button"
                                        className='text-secondary py-1 fw-bold ps-2'>
                                        {playlist.title}
                                    </div>
                                </div>
                            )) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSeveralVideos;