import React from 'react';
import Apple from '../icons/Apple.svg'
import Amazon from '../icons/Amazon.svg'
import Cuview from '../icons/CUVIEW.svg'
import Spotify from '../icons/SPOTIFY.svg'
import Deezer from '../icons/DEEZER.svg'
import Pandora from '../icons/PANDORA.svg'
import QRCode from "react-qr-code";
import merchIcon from '../icons/merchbar_logo_white.svg'



const AnalyzeBody = ({ activeData, resultLink}) => {

    const iconClickHandler = (url) =>{
        window.open(url)
    }

    return (
        <div className = "container-fluid py-5" style = {{background:"#036785"}}>
            { activeData && activeData.album ?
                <div className = "col-12 col-lg-10 mx-auto px-3 d-flex flex-column flex-md-row flex-lg-row">
                    <div className='col-12 col-lg-9 col-md-9 d-flex flex-column flex-md-row flex-lg-row'>
                        <div className = "col-8 mx-auto mx-md-0 col-lg-4 col-md-4 px-1 px-lg-2 text-center">
                            <img 
                                src = {activeData.album.cover_url} alt=""
                                role="button"
                                onClick = {() => iconClickHandler(activeData.album.url)}
                                className = "col-12 shadow-lg" style = {{width:"95%"}}/>
                        </div>
                        <div className='col-12 col-lg-8 col-md-8 ps-md-4 ps-lg-4'>
                            <div className = "col-12 text-center text-md-start text-lg-start">
                                <div className="fs-2 fw-bold text-white"
                                    role="button"
                                    onClick = {() => iconClickHandler(activeData.track_url)}
                                >
                                    {activeData.track_title}
                                </div>
                                    {
                                        activeData && activeData.members ? 
                                        activeData.members.map((member, index)=>
                                        <div className='d-flex align-items-center' key={index}>
                                            <div 
                                                className = "text-white pt-0 pt-lg-2" 
                                                role="button"
                                                onClick = {() => iconClickHandler(member.url)}>
                                                {member.title2}
                                            </div>
                                            <img 
                                                style={{width:"2rem", paddingLeft:"0.5rem" }}
                                                src = {merchIcon} 
                                                role = "button"
                                                onClick={()=>iconClickHandler(member.merch_url)}
                                                alt = ""/>
                                        </div>) : ""
                                    }
                            </div>
                        </div>  
                    </div>
                    <div className='col-12 col-lg-3 col-md-3 pt-3 pt-md-0'>
                        {
                            activeData.culinks ?
                            <div className = "col-8 col-md-10 col-lg-8 mx-auto mx-md-0 mx-lg-0">
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Apple}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.ITUNES.url)}
                                    />
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Amazon}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.AMAZON.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Cuview}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.CUVIEW.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Spotify}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.SPOTIFY.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Deezer}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.DEEZER.url)}/>
                                <img 
                                    className = "col-2 pe-2 pe-md-1 pe-lg-2" 
                                    src = {Pandora}
                                    role = "button"
                                    onClick = {() => iconClickHandler(activeData.culinks.RDIO.url)}/>
                            </div> : ""
                        }
                        <div  
                        className='mt-3 mt-lg-5 mt-md-5 col-4 col-md-8 col-lg-4 rounded mx-auto mx-md-0 mx-lg-0'
                        style={{ height: "auto", background:"white", padding:"0.7vw" }}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={resultLink}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                </div> : ""
            }
        </div>
    );
}

export default AnalyzeBody; 