import {
    GET_GENRES_FOR_ADMIN_REQUEST,
    GET_GENRES_FOR_ADMIN_SUCCESS,
    GET_GENRES_FOR_ADMIN_FAIL,

    GET_MOODS_FOR_ADMIN_REQUEST,
    GET_MOODS_FOR_ADMIN_SUCCESS,
    GET_MOODS_FOR_ADMIN_FAIL,

    GET_VIDEOGENRES_FOR_ADMIN_REQUEST,
    GET_VIDEOGENRES_FOR_ADMIN_SUCCESS,
    GET_VIDEOGENRES_FOR_ADMIN_FAIL,

    GET_TAGS_FOR_ADMIN_REQUEST,
    GET_TAGS_FOR_ADMIN_SUCCESS,
    GET_TAGS_FOR_ADMIN_FAIL,

    GET_ALBUMS_FOR_ADMIN_REQUEST,
    GET_ALBUMS_FOR_ADMIN_SUCCESS,
    GET_ALBUMS_FOR_ADMIN_FAIL,

    GET_TRACKS_FOR_ADMIN_REQUEST,
    GET_TRACKS_FOR_ADMIN_SUCCESS,
    GET_TRACKS_FOR_ADMIN_FAIL,

    ADD_VIDEOGENRE_REQUEST,
    ADD_VIDEOGENRE_SUCCESS,
    ADD_VIDEOGENRE_FAIL,

    EDIT_VIDEOGENRE_REQUEST,
    EDIT_VIDEOGENRE_SUCCESS,
    EDIT_VIDEOGENRE_FAIL,

    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAIL,

    EDIT_TAG_REQUEST,
    EDIT_TAG_SUCCESS,
    EDIT_TAG_FAIL,

    GET_ALBUMS_LENGTH_REQUEST,
    GET_ALBUMS_LENGTH_SUCCESS,
    GET_ALBUMS_LENGTH_FAIL,

    GET_TRACKS_LENGTH_REQUEST,
    GET_TRACKS_LENGTH_SUCCESS,
    GET_TRACKS_LENGTH_FAIL,

} from '../constants/AdminFilterConstants'


export const adminGenresReducer = (state = { adminGenres: []}, action) => {
    switch (action.type){
      case GET_GENRES_FOR_ADMIN_REQUEST:
        return { loadingAdminGenres: true, adminGenres : []}
  
      case GET_GENRES_FOR_ADMIN_SUCCESS:
        return { loadingAdminGenres: false, adminGenres: action.payload }
  
      case GET_GENRES_FOR_ADMIN_FAIL:
        return { loadingAdminGenres: false, error: action.payload }
  
      default:
        return state
    }
  }

  export const adminMoodsReducer = (state = { adminMoods: []}, action) => {
    switch (action.type){
      case GET_MOODS_FOR_ADMIN_REQUEST:
        return { loadingAdminMoods: true, adminMoods : []}
  
      case GET_MOODS_FOR_ADMIN_SUCCESS:
        return { loadingAdminMoods: false, adminMoods: action.payload }
  
      case GET_MOODS_FOR_ADMIN_FAIL:
        return { loadingAdminMoods: false, error: action.payload }
  
      default:
        return state
    }
  }


  export const adminVideogenresReducer = (state = { adminVideogenres:[]}, action) => {
    switch (action.type){

      case GET_VIDEOGENRES_FOR_ADMIN_REQUEST:
        if(action.index === 0){
          return { loading: true, adminVideogenres: []}
        } else {
          return {...state}
        }
  
      case GET_VIDEOGENRES_FOR_ADMIN_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            adminVideogenres: action.payload,
          } 
        } else {
          return { 
            loading: false, 
            adminVideogenres: [...state.adminVideogenres, ...action.payload],
          } 
        }

      case ADD_VIDEOGENRE_SUCCESS:
        return{
          adminVideogenres: [action.payload, ...state.adminVideogenres],
        }

      case ADD_VIDEOGENRE_FAIL:
        return {
          ...state
        }
  
      case GET_VIDEOGENRES_FOR_ADMIN_FAIL:
        return { 
          adminVideogenresLoading: false, 
          error: action.payload,
        ...state }
  
      default:
        return state
    }
  }


  export const adminTagsReducer = (state = { adminTags:[] }, action) => {
    switch (action.type){
      case GET_TAGS_FOR_ADMIN_REQUEST:
        if(action.index === 0){
          return { loading: true, adminTags: []}
        } else {
          return {...state}
        }
  
      case GET_TAGS_FOR_ADMIN_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            adminTags: action.payload
          } 
        } else {
          return { 
            loading: false, 
            adminTags: [...state.adminTags, ...action.payload]
          } 
        }

      case ADD_TAG_SUCCESS:
        return{
          adminTags : [action.payload, ...state.adminTags],
        }

       case ADD_TAG_FAIL:
        return{
          ...state
        } 
  
      case GET_TAGS_FOR_ADMIN_FAIL:
        return { 
          adminTagsLoading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }

  export const adminAlbumsReducer = (state = { adminAlbums: [], albumsLength: null}, action) => {
    switch (action.type){

      case GET_ALBUMS_LENGTH_REQUEST:
        return {...state, loading: true}
  
      case GET_ALBUMS_LENGTH_SUCCESS:
        return { 
          ...state, 
          loading: false, 
          albumsLength: action.payload }
  
      case GET_ALBUMS_LENGTH_FAIL:
        return { 
          loading: false, 
          error: action.payload, 
          ...state }

      case GET_ALBUMS_FOR_ADMIN_REQUEST:
        if(action.index === 0){
          return { 
            loading: true, 
            adminAlbums: [],
            ...state
          }
        } else {
          return {...state}
        }
  
      case GET_ALBUMS_FOR_ADMIN_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            ...state,
            loading: false, 
            adminAlbums: action.payload,
          } 
        } else {
          return { 
            ...state,
            loading: false, 
            adminAlbums: [...state.adminAlbums, ...action.payload],
          } 
        }
  
      case GET_ALBUMS_FOR_ADMIN_FAIL:
        return { 
          adminAlbumsLoading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }

  export const adminTracksReducer = (state = { adminTracks: [], tracksLength: null}, action) => {
    switch (action.type){

      case GET_TRACKS_LENGTH_REQUEST:
        return {...state, loading: true}
  
      case GET_TRACKS_LENGTH_SUCCESS:
        return { 
          ...state, 
          loading: false, 
          tracksLength: action.payload }
  
      case GET_TRACKS_LENGTH_FAIL:
        return { 
          loading: false, 
          error: action.payload, 
          ...state }

      case GET_TRACKS_FOR_ADMIN_REQUEST:
        return { 
          ...state,
          loading: true, 
        }
  
      case GET_TRACKS_FOR_ADMIN_SUCCESS:
        return { 
          ...state,
          loading: false, 
          adminTracks: action.payload 
        }
  
      case GET_TRACKS_FOR_ADMIN_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload 
        }
  
      default:
        return state
    }
  }