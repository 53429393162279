import React, { useEffect } from 'react';
import Edit_primary from '../adminIcons/Edit_primary.svg'
import CrossIcon from '../adminIcons/del.svg'
import { deleteBannerVideo } from '../../actions/BannerActions';
import { useDispatch } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const BannerVideoItem = (props) => {

    const dispatch = useDispatch()

    const deleteHandler = (video_id) =>{
        var answer = window.confirm("Do you really want to delete the banner video?");
        if (answer) {
            dispatch(deleteBannerVideo(video_id))
        }
    }

    const { attributes, listeners, setNodeRef, transform, transition, isDragging  } = useSortable(
        {id: props.video.position})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        gap:"10px"
    }


    return (
        <div className = 'col-3 rounded p-1' 
            id={props.video.position}
            ref = {setNodeRef}
            {...attributes}
            {...listeners}
            style = {style}>
            <div className = 'w-100 bg-success p-2 h-100 rounded'>
                <img 
                    className='w-100' 
                    src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.video_id}&s=300&qq=${Math.random()}`}
                    style={{aspectRatio:"16/9"}}></img>
                <div className = 'd-flex align-items-between'>
                    <div className = 'col-10 text-info overflow-hidden' style={{height:"3rem"}}>{props.video.banner_title}</div>
                    <div className = 'd-flex col-2'>
                        <img 
                            className = 'p-1' 
                            style={{width:"22px"}}
                            src = {Edit_primary}
                            role = 'button'
                            onClick={()=>props.setVideoForEdit(props.video)}
                            alt = ""></img>
                        <img 
                            className = 'p-1' 
                            role = 'button'
                            style={{width:"36px"}}  
                            src = {CrossIcon}
                            onClick={()=>deleteHandler(props.video.id)}
                            alt = ""></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerVideoItem;