import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import close from '../../app-main-content/icons/close.svg'
import but_ON from '../../app-main-content/icons/but_ON.svg'
import but_OFF from '../../app-main-content/icons/but_OFF.svg'
import { getGroupsForAdmin } from '../../actions/GroupsActions';
import { addPlaylistToGroup, deletePlaylistFromGroup } from '../../actions/AdminPlaylistsActions';


const AddToGroup = (props) => {

    const dispatch = useDispatch()

    const { adminGroups } = useSelector(state=>state.adminGroups)

    useEffect(()=>{
        dispatch(getGroupsForAdmin())
    },[])


    const clickHandler = (group) => {
        if(!props.playlist.groups.includes(group.id)){
            dispatch(addPlaylistToGroup(group, props.playlist.id))
        }
        else  {
            dispatch(deletePlaylistFromGroup(group.id, props.playlist.id))
        }
      }

    return (
        <div style={{width:"300px"}}>
            <div className = 'w-100 bg-secondary d-flex justify-content-between py-2 px-2 rounded-top'>
                <div className='text-white px-3' style={{fontWeight: 500}}>Add to Group</div>
                <img src={close} alt = "close" role="button" onClick={()=>props.setIsActive(false)}/>
            </div>
            <div className='col-12 p-3 rounded-bottom' style={{background:"#F3F9FF"}}>
                <div className = "w-100 addToplaylist-list-wrapper">
                    <div>
                        {
                            adminGroups && adminGroups.length ?
                            adminGroups.filter(gr=>gr.id!==props.gr).map((group, index)=>(
                                <div className='d-flex' 
                                    key = {index}
                                    onClick={()=>clickHandler(group)}>
                                    {
                                        props.playlist.groups && props.playlist.groups.includes(group.id) ?
                                        <img src = {but_ON} alt = "" /> : 
                                        <img src = {but_OFF} alt = ""/> 
                                    }
                                    <div key = {index}
                                        role = "button"
                                        className='text-secondary py-1 fw-bold ps-2'>
                                        {group.title}
                                    </div>
                                </div>
                            )) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddToGroup;