import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerThemes } from '../../actions/BannerActions';

const ChoosePlaylist = (props) => {

    const dispatch = useDispatch()

    const { bannerPlaylists } = useSelector(state=>state.bannerPlaylists)

    const clickHandler = (playlist) => {
        props.setPlaylist(playlist)
        dispatch(getBannerThemes(playlist.id))
        props.setIndex(3)
    }

    return (
        <div>
            <div className = 'px-2 text-secondary fs-8 pb-3 fw-bold'>CHOOSE PLAYLIST</div>
            {
                bannerPlaylists && bannerPlaylists.length ? 
                bannerPlaylists.map((playlist, index)=>(
                    <div 
                        className = 'd-flex border border-primary p-2 bg-secondary my-2 rounded align-items-center' 
                        key = {index}
                        role = 'button'
                        onClick={()=>clickHandler(playlist)}
                        >
                        <img 
                            src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${playlist.id}&s=300`}
                            style={{width:"5rem", aspectRatio:"16/9"}}/>
                        <div className = 'text-white ps-4'>{playlist.title}</div>
                    </div> 
                )) : ""
            }
        </div>
    );
};

export default ChoosePlaylist;