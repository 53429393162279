import React, {useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerVideos, arrangeBannerVideos } from '../../actions/BannerActions';
import addIcon from '../adminIcons/add.svg'
import AddBannerVideo from './AddBannerVideo';
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import EditBannerVideo from './EditBannerVideo';
import BannerVideoItem from './BannerVideoItem';
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
  } from "@dnd-kit/sortable";


const MainPage = () => {

    const dispatch = useDispatch()

    const editBannerVideoRef = useRef()

    useEffect(()=>{
        dispatch(getBannerVideos())
    },[])

    const [videoForEdit, setVideoForEdit] = useState({})

    const {loadingBannerVideos, bannerVideos} = useSelector(state=>state.bannerVideos)

    const [addActive, setAddActive] = useState(false)

    const [items, setItems] = useState()

    const {width, height} = useWindowDimentions()


    useEffect(()=>{
        if (bannerVideos && bannerVideos.length){
            setItems(bannerVideos)
        }
    },[bannerVideos])


    const sensors = useSensors(
        useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
        }),
        useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
        }),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const {active, over} = event;
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.map(item=>item.position).indexOf(active.id);
            const newIndex = items.map(item=>item.position).indexOf(over.id);
            if (active.id>over.id){
                items.forEach(item => {
                    if(item.position < active.id && item.position >= over.id){
                        item.position += 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } else if (active.id<over.id){
                items.forEach(item => {
                    if(item.position > active.id && item.position <= over.id){
                        item.position -= 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } 
            return arrayMove(items, oldIndex, newIndex);
          });
          dispatch(arrangeBannerVideos(active.id, over.id))
        }
    }

    return (
        <div className='col-10 py-5 ps-5' style={{marginLeft:'15rem', minHeight:"100vh"}}>
            <div className = 'pb-4 d-flex justify-content-between align-items-center pe-5'>
                <div className = 'text-secondary' 
                    style={{fontWeight:800, fontSize:'40px'}}
                >MAIN PAGE</div>
                <div className = 'bg-secondary rounded-pill px-3 d-flex align-items-center'
                    style={{height:"40px"}}
                    role='button'
                    onClick = {()=>{setAddActive(true)}}
                    >
                    <img src = {addIcon}/>
                    <div className='text-white ps-1 text-nowrap'
                    >Add Banner Video</div>
                </div>
            </div>
            <DndContext 
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragEnd={handleDragEnd}
                >
                <div className='w-100 d-flex flex-wrap pe-5'>
                    {
                        items && items.length ? 
                        <SortableContext items = {items.map(video=>video.position)} strategy={verticalListSortingStrategy}>
                            {
                                items.map((video, index)=> (
                                    <BannerVideoItem
                                        key = {index}
                                        video = {video}
                                        setVideoForEdit = {setVideoForEdit}
                                    />
                                ))
                            }
                        </SortableContext>
                         : ""
                    }
                </div>
            </DndContext>
            <EditBannerVideo
                videoForEdit = {videoForEdit}
                editBannerVideoRef = {editBannerVideoRef}
                setVideoForEdit = {setVideoForEdit}
            />
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {0}
                right = {0}
                content = {
                    <AddBannerVideo
                        setIsActive = {setAddActive}
                        width = {width}
                        height = {height}
                    /> 
                }
            /> 
        </div>
    );
};

export default MainPage;