export const ADD_PLAYLIST_REQUEST = 'ADD_PLAYLIST_REQUEST'
export const ADD_PLAYLIST_SUCCESS = 'ADD_PLAYLIST_SUCCESS'
export const ADD_PLAYLIST_FAIL = 'ADD_PLAYLIST_FAIL'

export const CHECK_VIDEO_IN_PLAYLIST_REQUEST = 'CHECK_VIDEO_IN_PLAYLIST_REQUEST'
export const CHECK_VIDEO_IN_PLAYLIST_SUCCESS = 'CHECK_VIDEO_IN_PLAYLIST_SUCCESS'
export const CHECK_VIDEO_IN_PLAYLIST_FAIL = 'CHECK_VIDEO_IN_PLAYLIST_FAIL'

export const USER_PLAYLISTS_REQUEST = 'USER_PLAYLISTS_REQUEST'
export const USER_PLAYLISTS_SUCCESS = 'USER_PLAYLISTS_SUCCESS'
export const USER_PLAYLISTS_FAIL = 'USER_PLAYLISTS_FAIL'

export const ALL_PLAYLISTS_REQUEST = 'ALL_PLAYLISTS_REQUEST'
export const ALL_PLAYLISTS_SUCCESS = 'ALL_PLAYLISTS_SUCCESS'
export const ALL_PLAYLISTS_FAIL = 'ALL_PLAYLISTS_FAIL'

export const GROUP_PLAYLISTS_REQUEST = 'GROUP_PLAYLISTS_REQUEST'
export const GROUP_PLAYLISTS_SUCCESS = 'GROUP_PLAYLISTS_SUCCESS'
export const GROUP_PLAYLISTS_FAIL = 'GROUP_PLAYLISTS_FAIL'

export const PLAYLIST_VIDEOS_REQUEST = 'PLAYLIST_VIDEOS_REQUEST'
export const PLAYLIST_VIDEOS_SUCCESS = 'PLAYLIST_VIDEOS_SUCCESS'
export const PLAYLIST_VIDEOS_FAIL = 'PLAYLIST_VIDEOS_FAIL'

export const PLAYLIST_GROUPS_REQUEST = 'PLAYLIST_GROUPS_REQUEST'
export const PLAYLIST_GROUPS_SUCCESS = 'PLAYLIST_GROUPS_SUCCESS'
export const PLAYLIST_GROUPS_FAIL = 'PLAYLIST_GROUPS_FAIL'

export const ADD_VIDEO_TO_PLAYLIST_REQUEST = 'ADD_VIDEO_TO_PLAYLIST_REQUEST'
export const ADD_VIDEO_TO_PLAYLIST_SUCCESS = 'ADD_VIDEO_TO_PLAYLIST_SUCCESS'
export const ADD_VIDEO_TO_PLAYLIST_FAIL = 'ADD_VIDEO_TO_PLAYLIST_FAIL'

export const DELETE_VIDEO_FROM_PLAYLIST_REQUEST = 'DELETE_VIDEO_FROM_PLAYLIST_REQUEST'
export const DELETE_VIDEO_FROM_PLAYLIST_SUCCESS = 'DELETE_VIDEO_FROM_PLAYLIST_SUCCESS'
export const DELETE_VIDEO_FROM_PLAYLIST_FAIL = 'DELETE_VIDEO_FROM_PLAYLIST_FAIL'

export const DOWNLOAD_PLAYLIST_REQUEST = 'DOWNLOAD_PLAYLIST_REQUEST'
export const DOWNLOAD_PLAYLIST_SUCCESS = 'DOWNLOAD_PLAYLIST_SUCCESS'
export const DOWNLOAD_PLAYLIST_FAIL = 'DOWNLOAD_PLAYLIST_FAIL'

export const DELETE_PLAYLIST_REQUEST = 'DELETE_PLAYLIST_REQUEST'
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS'
export const DELETE_PLAYLIST_FAIL = 'DELETE_PLAYLIST_FAIL'

export const ARRANGE_VIDEOS_REQUEST = 'ARRANGE_VIDEOS_REQUEST'
export const ARRANGE_VIDEOS_SUCCESS = 'ARRANGE_VIDEOS_SUCCESS'
export const ARRANGE_VIDEOS_FAIL = 'ARRANGE_VIDEOS_FAIL'

export const ADD_FULL_PLAYLIST_REQUEST = 'ADD_FULL_PLAYLIST_REQUEST'
export const ADD_FULL_PLAYLIST_SUCCESS = 'ADD_FULL_PLAYLIST_SUCCESS'
export const ADD_FULL_PLAYLIST_FAIL = 'ADD_FULL_PLAYLIST_FAIL'

export const TEMPORARY_PLAYLIST_REQUEST = 'TEMPORARY_PLAYLIST_REQUEST'
export const TEMPORARY_PLAYLIST_SUCCESS = 'TEMPORARY_PLAYLIST_SUCCESS'
export const TEMPORARY_PLAYLIST_FAIL = 'TEMPORARY_PLAYLIST_FAIL'

export const ADD_PLAYLIST_TO_GROUP_REQUEST = 'ADD_PLAYLIST_TO_GROUP_REQUEST'
export const ADD_PLAYLIST_TO_GROUP_SUCCESS = 'ADD_PLAYLIST_TO_GROUP_SUCCESS'
export const ADD_PLAYLIST_TO_GROUP_FAIL = 'ADD_PLAYLIST_TO_GROUP_FAIL'

export const DELETE_PLAYLIST_FROM_GROUP_REQUEST = 'DELETE_PLAYLIST_FROM_GROUP_REQUEST'
export const DELETE_PLAYLIST_FROM_GROUP_SUCCESS = 'DELETE_PLAYLIST_FROM_GROUP_SUCCESS'
export const DELETE_PLAYLIST_FROM_GROUP_FAIL = 'DELETE_PLAYLIST_FROM_GROUP_FAIL'