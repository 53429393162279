import React, {useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminVideos from './AdminVideos/AdminVideos'
import AdminLayout from './AdminLayout';
import AddVideo from './AddVideo/AddVideo';
import AdminPlaylists from './AdminPlaylists/AdminPlaylists';
import AddAdminPlaylist from './AdminPlaylists/AddAdminPlaylist';
import EditAdminPlaylist from './AdminPlaylists/EditAdminPlaylist';
import EditVideo from './AddVideo/EditVideo';
import MainPage from './mainPage/MainPage';
import ThemesPage from './Themes/ThemesPage';
import EditTheme from './Themes/EditTheme';
import { useSelector } from 'react-redux';

const AdminContent = () => {

    const { user, userLoading } = useSelector(state=>state.user)

    const [changes, setChanges] = useState(false)

        return (
            <>
                {
                    user && user.id ? 
                    <div className='bg-warning' >            
                        <Routes>
                            <Route 
                                element = {
                                    <AdminLayout
                                        changes = {changes}
                                        setChanges = {setChanges}
                                    />
                                    } >
                                <Route path = '/adminVideos' element = {<AdminVideos/>}/>
                                <Route 
                                    path = '/addVideo' 
                                    element = {
                                        <AddVideo
                                            changes = {changes}
                                            setChanges = {setChanges}
                                        />
                                    }/>
                                <Route path = '/adminPlaylists' element = {<AdminPlaylists/>}/>
                                <Route 
                                    path = '/addPlaylist' 
                                    element={
                                        <AddAdminPlaylist
                                            changes = {changes}
                                            setChanges = {setChanges}
                                        />
                                        }/>
                                <Route 
                                    path = '/editPlaylist/:playlist_id' 
                                    element={
                                        <EditAdminPlaylist
                                            changes = {changes}
                                            setChanges = {setChanges}
                                        />
                                        }/>
                                <Route 
                                    path = '/editVideo/:video_id' 
                                    element={
                                    <EditVideo
                                        changes = {changes}
                                        setChanges = {setChanges}
                                    />
                                    }/>
                                <Route path = '/main' element={<MainPage/>}/>
                                <Route path = '/themes' element={<ThemesPage/>}/>
                                <Route 
                                    path = '/editTheme/:group_id' 
                                    element={
                                    <EditTheme
                                        changes = {changes}
                                        setChanges = {setChanges}
                                    />
                                    }/>
                            </Route>
                        </Routes>
                    </div> :""
                }
            </>
        )
};

export default AdminContent;