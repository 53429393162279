import React, {useState, useEffect} from 'react';
import Attach from '../adminIcons/Attach.svg'
import { uploadVideo } from '../../actions/videoActions';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy'
import Loader from "react-js-loader"

const Uploads = (props) => {

    const [videoFile, setVideoFile] = useState()
    const [imageURL, setImageUrl] = useState()

    const dispatch = useDispatch()

    const {videoLoading, uploadedVideo} = useSelector(state=>state.adminVideos)

    const handleChange = (e) =>{
        setVideoFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        props.setVideoUrl(u)
        props.setChanges(true)
    }

    const handleImage = (e) => {
        props.setImageFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        setImageUrl(u)
        props.setChanges(true)
    }

    const handleVideoUpload =() => {
        dispatch(uploadVideo(videoFile))
        props.setChanges(true)
        setVideoFile("")
    }

    console.log(uploadedVideo)

    return (
        <div className = "col-3 bg-success rounded p-2">
            <div className = "text-info pt-4 pb-2">Upload Video</div>
            {
                props.videoUrl ? 
                <div className = "bg-warning p-2 mt-3 rounded">
                    <ReactPlayer
                        // ref = {}
                        className='react-player'
                        controls
                        url = {props.videoUrl}
                        // light = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${12}&s=600`}
                        width="100%"
                        height="230px"
                    />
                    {
                        videoLoading ? 
                        <div className={"item"}>
                            <Loader type="bubble-top" bgColor={"#037A9A"} color={"#037A9A"} title={"Uploading..."} size={25} />
                        </div> : 
                        uploadedVideo && uploadedVideo.file_path_linux ? 
                        <div className = "text-info py-2 ps-2">Uploaded</div> :
                        <div className = "w-100 d-flex justify-content-between align-items-center mt-3">
                            <div className = "text-info w-75 text-decoration-underline text-wrap overflow-hidden">{videoFile.name}</div>
                            <div 
                                className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                role='button'
                                onClick={handleVideoUpload}
                            >Upload</div>
                            <input 
                                id = "refile-input"
                                type="file" 
                                name="videoFile"
                                onChange={handleChange}
                                style={{display:"none"}}
                                />
                            <label for="refile-input">
                                <div 
                                    className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                    role='button'
                                >Change</div>
                            </label>
                        </div> 
                    }
                </div> 
                : 
                <div className = "w-100 px-3 py-5 bg-warning rounded">
                    <div className = "w-100 py-5 d-flex bg-secondary justify-content-center">
                        <input 
                            id = "file-input"
                            type="file" 
                            name="videoFile"
                            onChange={handleChange}
                            style={{display:"none"}}
                            />
                        <label for="file-input">
                            <img src = {Attach} alt = "" role="button"/>
                        </label>
                    </div>
            </div>
            }

            <div className = "bg-warning p-2 mt-3 rounded">
                <div className = "text-info pb-2">Upload Thumbnail</div>
                {
                    props.imageFile && props.imageFile.name ? 
                    <>
                        <img src = {imageURL} alt = ""
                            style = {{width:"100%", aspectRatio:"16/9"}}
                        />
                        <div className = "w-100 d-flex justify-content-between align-items-center mt-3">
                            <div className = "text-info w-75 text-decoration-underline text-wrap overflow-hidden">{props.imageFile.name}</div>
                            <input 
                                id = "reimage-input"
                                type="file" 
                                name="imageFile"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={handleImage}
                                style={{display:"none"}}
                                />
                                <label for="reimage-input">
                                    <div 
                                        className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                        role='button'
                                    >Change</div>
                                </label>
                        </div>
                    </>
                    : 
                    <div className = "w-100 px-3 py-5 bg-warning rounded">
                        <div className = "w-100 py-5 d-flex bg-secondary justify-content-center">
                            <input 
                                id = "image-input"
                                type="file" 
                                name="imageFile"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={handleImage}
                                style={{display:"none"}}
                                />
                            <label for="image-input">
                                <img src = {Attach} alt = "" role="button"/>
                            </label>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Uploads;