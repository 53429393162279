import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DeleteIcon from '../adminIcons/DeleteIcon.svg'
import EditIcon from '../adminIcons/EditIcon.svg'
import PlusIcon from '../adminIcons/PlusIcon.svg'
import { deleteGroupPlaylist } from '../../actions/GroupsActions.js'; 
import { useNavigate } from 'react-router-dom';
import AddToGroup from '../AdminPlaylists/AddToGroup.js';
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';


const PlaylistItem = (props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const [addActive, setAddActive] = useState(false)

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    const deleteHandler = (playlist) => {
        var answer = window.confirm("Do you really want to delete playlist from group?");
        if (answer) {
            dispatch(deleteGroupPlaylist(props.group_id, playlist.id, playlist.position))
        }
    }

    
    const { attributes, listeners, setNodeRef, transform, transition, isDragging  } = useSortable(
        {id: props.playlist.position})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        gap:"10px"
    }



    const editPlaylistHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(`/editPlaylist/${props.playlist.id}`)
            }
        } else {
            navigate(`/editPlaylist/${props.playlist.id}`)
        }
    }

    return (
        <div className='col-12 bg-warning border-bottom border-success d-flex align-items-center p-2'
            id={props.playlist.position}
            ref = {setNodeRef}
            {...attributes}
            {...listeners}
            style = {style}
        >
            <div className = 'col-1 d-flex justify-content-center'>
                <div className='col-1 bg-success border border-secondary text-info text-center rounded p-1'
                    style = {{width:"34px", height:"34px"}}>
                    {props.playlist.position}
                </div>
            </div>
            <div className = 'col-2 d-flex justify-content-center'>
                <img 
                    src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.playlist.id}&s=300`}
                    alt = ""
                    className='border border-secondary'
                    style={{aspectRatio:"16/9", width:"125px"}}
                />
            </div>
            <div className = 'd-flex col-9'>
                <div className = "text-white overflow-hidden col-8">{props.playlist.title}</div>
                <div className = "text-white overflow-hidden col-2">{props.playlist.number_of_videos + ' Videos'}</div>
                <div className='d-flex justify-content-center col-2'
                    style={{gap:"10px"}}>
                    <img 
                        src = {EditIcon} alt = "" 
                        role='button'
                        onClick={()=>editPlaylistHandler()}/>
                    <img 
                        src = {PlusIcon} 
                        alt = "" role='button'
                        onClick={addClickHandler}/>
                    <img 
                        src = {DeleteIcon} alt = "" 
                        role='button'
                        onClick={()=>deleteHandler(props.playlist)}
                    />
                </div>
            </div>
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddToGroup
                        setIsActive = {setAddActive}
                        playlist = {props.playlist}
                        gr = {props.group_id}
                    /> 
                }
            /> 
        </div>
    );
};

export default PlaylistItem;