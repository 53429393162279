import React, {useState} from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function ThemesMobile(props) {

    const [open, setOpen] = useState()

    const navigate = useNavigate()

    const groupList = useSelector(state => state.allPlaylists)
  
    const {error, loading, allPlaylists} = groupList

    const grClickHandler = (group_id, playlist_id, video_id) => {
        localStorage.setItem("group_id", group_id);
        localStorage.setItem('playlist_id', playlist_id); 
        localStorage.setItem('video_id', video_id); 
        if(props.user){
            navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
        } else {
            navigate('/login')
        }
    }

    const plClickHandler = (e, group_id, playlist_id, video_id) => {
        e.stopPropagation()
        localStorage.setItem("group_id", group_id);
        localStorage.setItem('playlist_id', playlist_id); 
        localStorage.setItem('video_id', video_id); 
        if(props.user){
            navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
        } else {
            navigate('/login')
        }
    }

    return (
        <>
            {
                allPlaylists && allPlaylists.length ?
                allPlaylists.map((group, ind) => 
                    <li key = {ind} className="nav-item" 
                        onClick={()=>
                            grClickHandler(group.id, group.playlists[0].id, group.playlists[0].video_id)}>
                        <div className="btn-group w-100">
                            <button className="btn text-start nav-link" type="button">{group.title}</button>
                            <button type="button" className="btn dropdown-toggle dropdown-toggle-split collapsed"  data-bs-toggle="collapse" href={`#collapseThemes_${ind}`} aria-expanded="false">
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                        </div>
                            <ul className="nav flex-column ps-4 collapse" id={`collapseThemes_${ind}`} >
                            {
                                group.playlists && group.playlists.length ? 
                                group.playlists.map (pl=> 
                                    <li key = {pl.id} 
                                        style={{cursor:'pointer'}}
                                        onClick={(e)=>plClickHandler(e, group.id, pl.id, pl.video_id)}>
                                        <a className="btn text-start nav-link playlist-item" >{pl.title}</a>
                                    </li>
                                ) : ''
                            }
                        </ul>
                    </li>) : ''
            }
        </>
    )
}

export default ThemesMobile;