import React, {useState, useEffect} from 'react';
import { getGroupInfo } from '../../actions/GroupsActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import { editGroup } from '../../actions/GroupsActions.js';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import BackIcon from '../adminIcons/BackIcon.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import PlaylistItem from './PlaylistItem';
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
  } from "@dnd-kit/sortable";

const EditTheme = (props) => {

    const [statusOn, setStatusOn] = useState(0)
    const [title, setTitle] = useState("")
    const [items, setItems] = useState([])

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {group_id} = useParams()

    const {loadingGroupInfo, groupInfo} = useSelector(state=>state.groupInfo)

    useEffect(()=>{
        dispatch(getGroupInfo(group_id))
    },[])

    useEffect(()=>{
        if(groupInfo){
            setTitle(groupInfo.title)
            setStatusOn(groupInfo.status)
            if(groupInfo.playlists){
                setItems(groupInfo.playlists)
            }
        }
    },[groupInfo])


    const sensors = useSensors(
        useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
        }),
        useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
        }),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        props.setChanges(true)
        const {active, over} = event;
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.map(item=>item.position).indexOf(active.id);
            const newIndex = items.map(item=>item.position).indexOf(over.id);
            if (active.id>over.id){
                items.forEach(item => {
                    if(item.position < active.id && item.position >= over.id){
                        item.position += 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } else if (active.id<over.id){
                items.forEach(item => {
                    if(item.position > active.id && item.position <= over.id){
                        item.position -= 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } 
            return arrayMove(items, oldIndex, newIndex);
          });
        }
    }

    const editGroupHandler = () => {
        if(!title.length){
            alert('Please, Provide Title') 
        } else { 
            let positions = []
            items.forEach(item=>positions.push({id: item.id, position: item.position})) 
            dispatch(editGroup(group_id, title, statusOn, positions ))
            props.setChanges(false)
            navigate('/themes')     
        }
    }

    const backHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }


    return (

        <div className = "col-10 p-4" style={{marginLeft:"15rem", minHeight:"100vh"}}>   
            {
                loadingGroupInfo ? <>loading...</> : groupInfo ? 
                <> 
                    <div className = "w-100 d-flex flex-column align-items-center pb-5">
                    <div className='col-9 d-flex align-items-center justify-content-start pb-4'>
                        <img 
                            src = {BackIcon} 
                            role = 'button'
                            onClick={backHandler}
                            alt = ""/>
                        <div className = "fw-bold fs-7 ps-4 text-secondary" 
                            role='button' 
                        >{groupInfo.title}</div>
                    </div>
                        <div className = "col-9 bg-success me-3 rounded px-3 py-5">
                            <div className = "text-secondary fw-bold pb-2">Title*</div>
                            <input
                                type="text"
                                className="col-12 rounded fs-5 border-0 bg-warning text-white py-3 search-input ps-3"
                                onChange = {(e)=>{setTitle(e.target.value); props.setChanges(true)}}
                                value = {title}
                            />
                            <div className = "text-secondary fw-bold pt-4 pb-2">Playlists</div>
                            <DndContext 
                                    sensors={sensors}
                                    collisionDetection={closestCorners}
                                    onDragEnd={handleDragEnd}
                                    >
                                <div className = "bg-warning w-100 rounded addToplaylist-list-wrapper" style = {{maxHeight:"500px"}}>
                                    {
                                        items && items.length ?
                                        <SortableContext items = {items.map(playlist=>playlist.position)} strategy={verticalListSortingStrategy}>
                                            {
                                                items.map((playlist, index)=>(
                                                    <PlaylistItem
                                                        group_id = {groupInfo.id}
                                                        playlist = {playlist}
                                                        key = {playlist.id}
                                                        changes = {props.changes}
                                                        setChanges = {props.setChanges}
                                                    />
                                                ))
                                            }
                                        </SortableContext> : ""
                                    }
                                </div>  
                            </DndContext>
                            <div className = "py-4" role = "button">
                                {
                                    statusOn === 0 ? 
                                    <div className = "d-flex align-items-center">
                                        <img src = {SwitchOff} alt = "" onClick={()=>{setStatusOn(1); props.setChanges(true)}}/>
                                        <div className = "text-info ms-4">Access OFF</div>
                                    </div>
                                     : 
                                     <div className = "d-flex align-items-center">
                                        <img src = {SwitchOn} alt = "" onClick={()=>{setStatusOn(0); props.setChanges(true)}}/>
                                        <div className = "text-info ms-4">Access ON</div>
                                    </div>
                                }
                            </div>
                            <div className = "w-100 d-flex justify-content-end">
                                <div 
                                    className = "text-white px-5 py-2 bg-secondary rounded-pill"
                                    role = "button"
                                    onClick = {()=>editGroupHandler()}
                                >Save</div>
                            </div>
                        </div>
                    </div>
                </> :                 
                <div className='w-100 h-100 d-flex justify-content-center align-items-center text-info fw-bold fs-4'>
                    There's Some Error, please, try again later
                </div>
            }
        </div>
    );
};

export default EditTheme;